import * as Yup from "yup";

export const validationSchema = () => {
  return Yup.object().shape({
    name: Yup.string().required("This field is required"),

    ms_games_per_session: Yup.number().required("This field is required"),
    ms_daily_interrupted_games_limit: Yup.number().required(
      "This field is required"
    ),

    ms_daily_match_limit: Yup.string().required("This field is required"),
    ms_daily_squad_battles_limit: Yup.number().required(
      "This field is required"
    ),
    ms_daily_online_match_limit: Yup.number().required(
      "This field is required"
    ),
    ms_session_timeout_factor: Yup.number().required("This field is required"),
    ms_max_games_per_console_daily: Yup.number().required(
      "This field is required"
    ),
    ms_stop_after_xcaptchas: Yup.number().required("This field is required"),
    ms_sleep_x_hours_after_captcha: Yup.number().required(
      "This field is required"
    ),
    ms_stop_console_after_x_captcha_fails: Yup.number().required(
      "This field is required"
    ),
    ms_session_sleep_timeout: Yup.number().required("This field is required"),
    allow_tm_unlocked_to_play: Yup.boolean().required("This field is required"),
    objectives: Yup.boolean().required("This field is required"),

    os_allow_digital_fifa_owners_to_play: Yup.boolean().required(
      "This field is required"
    ),
    log_in_to_webapp_daily: Yup.boolean().required("This field is required"),
    os_asian_mode: Yup.boolean().required("This field is required"),
    ai: Yup.boolean().required("This field is required"),
    os_work_space: Yup.string().required("This field is required"),
    os_quit_if_servers_unreachable: Yup.boolean().required(
      "This field is required"
    ),
    server_issue_throttling: Yup.boolean().required("This field is required"),
    os_min_coins_to_mule: Yup.number().required("This field is required"),
    os_gpu_device_number: Yup.number().required("This field is required"),
    os_video_decoding_codec: Yup.string().required("This field is required"),
    force_public_ip_match: Yup.boolean().required("This field is required"),
    force_local_ip_match: Yup.boolean().required("This field is required"),
    ip_check_interval: Yup.number().required("This field is required"),
    instance_limit: Yup.number().required("This field is required"),
    local_ip: Yup.string(),
    port: Yup.number(),
    login: Yup.string(),
    passw: Yup.string(),
    isp_name: Yup.string(),
    total_accs: Yup.number(),
    assign_from_alias: Yup.string()
  });
};
