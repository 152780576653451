import { gql } from "apollo-boost";

export const ADDCAPTCHAKEY = gql`
  mutation addCaptchaKey(
    $id: Int!
    $captcha_key: String
    $muling_customer_id: String
    $mm_integration: Boolean
  ) {
    addCaptchaKey(
      id: $id
      captcha_key: $captcha_key
      muling_customer_id: $muling_customer_id
      mm_integration: $mm_integration
    )
  }
`;

export const GETSETTINGS = gql`
  query getSettings {
    getSettings
  }
`;
export const GETSCRIPTSETTINGS = gql`
  query getScriptSettings {
    getScriptSettings
  }
`;

export const ADD_SCRIPT_SETTING = gql`
  mutation addScript($input: JSON!) {
    addScript(input: $input)
  }
`;

export const DELETE_SCRIPT_SETTING = gql`
  mutation deleteScript($id: Int!) {
    deleteScript(id: $id)
  }
`;

export const UPDATE_SCRIPT_SETTING = gql`
  mutation updateScript($id: Int!, $input: JSON!) {
    updateScript(id: $id, input: $input)
  }
`;
