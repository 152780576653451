function StatusBarCoinsCounter() {}

const getMegas = value => {
  return Math.round(value / 100000) / 10 + "M";
};

StatusBarCoinsCounter.prototype.init = function (params) {
  this.params = params;

  this.eGui = document.createElement("div");
  this.eGui.className = "ag-status-name-value";

  var label = document.createElement("span");
  label.innerText = "Coins: ";
  this.eGui.appendChild(label);

  this.eCount = document.createElement("span");
  this.eCount.className = "ag-status-name-value-value";

  this.eGui.appendChild(this.eCount);

  params.api.addEventListener("gridReady", this.onGridReady.bind(this));
  params.api.addEventListener("modelUpdated", this.onModelUpdated.bind(this));
  params.api.addEventListener(
    "rowDataChanged",
    this.onRowDataChanged.bind(this)
  );
};

StatusBarCoinsCounter.prototype.getGui = function () {
  return this.eGui;
};

StatusBarCoinsCounter.prototype.destroy = function () {
  this.params.api.removeEventListener("gridReady", this.onGridReady);
};

StatusBarCoinsCounter.prototype.onGridReady = function () {
  this.refresh();
};

StatusBarCoinsCounter.prototype.refresh = function (fromModel) {
  if (
    (!fromModel ||
      !this.lastUpdateTime ||
      Date.now() - this.lastUpdateTime > 2000) &&
    this.params.api.getModel
  ) {
    let currentCoins = 0;
    let totalCoins = 0;
    this.params.api.forEachNodeAfterFilter(row => {
      if (row.data) totalCoins += parseInt(row.data.coins);
    });

    const model = this.params.api.getModel();
    console.log("=== ==> model:", model);

    model &&
      model.rowsToDisplay.forEach(row => {
        if (row.data) currentCoins += parseInt(row.data.coins);
      });
    this.eCount.innerText = `${getMegas(currentCoins)} of ${getMegas(
      totalCoins
    )}`;
    this.lastUpdateTime = Date.now();
  }
};

StatusBarCoinsCounter.prototype.onModelUpdated = function () {
  this.refresh(true);
};

StatusBarCoinsCounter.prototype.onRowDataChanged = function () {
  this.refresh();
};

export default StatusBarCoinsCounter;
