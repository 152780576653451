import React, { PureComponent } from "react"
import { BrowserRouter, Switch, Route } from "react-router-dom"
import Login from "./pages/Login"
import AppContainer from "./containers/AppContainer"
// import Admin from "./Admin";
// import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
// import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-alpine.css";

// import {LicenseManager} from "@ag-grid-enterprise/core";
// LicenseManager.setLicenseKey("your license key");

class App extends PureComponent {
  render() {
    return (
      <BrowserRouter>
        <React.Suspense fallback={<div />}>
          <Switch>
            {/* <Route exact path="/" component={Login} /> */}
            <Route exact path="/login" component={Login} />
            {/* <Route path="/admin" render={() => <Admin />} /> */}
            <Route render={() => <AppContainer />} />
          </Switch>
        </React.Suspense>
      </BrowserRouter>
    )
  }
}
export default App
