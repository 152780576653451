export const ms = [
  { key: "ms_games_per_session", title: "Game per session" },
  { key: "ms_daily_match_limit", title: "Daily match limit" },
  {
    key: "ms_daily_interrupted_games_limit",
    title: "Daily interrupted games limit"
  },
  {
    key: "ms_daily_squad_battles_limit",
    title: "Daily squad battles limit"
  },
  { key: "ms_daily_online_match_limit", title: "Daily online match limit" },
  { key: "ms_session_timeout_factor", title: "Session timeout factor" },
  {
    key: "ms_max_games_per_console_daily",
    title: "Max games per console daily"
  },
  { key: "ms_stop_after_xcaptchas", title: "Stop after X captchas" },
  {
    key: "ms_sleep_x_hours_after_captcha",
    title: "Sleep X hours after captcha"
  },
  {
    key: "ms_stop_console_after_x_captcha_fails",
    title: "Stop console after X captcha fails"
  },
  { key: "ms_session_sleep_timeout", title: "Session sleep timeout" },
  { key: "allow_tm_unlocked_to_play", title: "Allow TM unlocked to play" }
];
export const os = [
  { key: "objectives", title: "Objectives" },
  { key: "os_ntpserver", title: "NTP server" },
  {
    key: "os_allow_digital_fifa_owners_to_play",
    title: "Allow digital fifa owner to play"
  },
  { key: "log_in_to_webapp_daily", title: "Log in to webapp daily" },
  { key: "os_asian_mode", title: "Asian mode" },
  { key: "ai", title: "Enable AI" },
  { key: "os_work_space", title: "Work space" },
  {
    key: "os_quit_if_servers_unreachable",
    title: "Quit if servers unreachable"
  },
  {
    key: "server_issue_throttling",
    title: "Server issue throttling"
  },
  { key: "instance_limit", title: "Instance Limit" },
  { key: "os_days_to_trade", title: "Days to trade" },
  { key: "pack_preview", title: "Pack Preview" },
  { key: "allow_auto_mulling", title: "Allow auto mulling" },
  { key: "os_min_coins_to_mule", title: "Min coins to mule" },
  { key: "os_gpu_device_number", title: "GPU Device Number" },
  { key: "os_video_decoding_codec", title: "Video Decoding Codec" },
  { key: "discord_chat_id", title: "Discord chat id" }
];
export const rs = [
  { key: "local_ip", title: "Local IP" },
  { key: "port", title: "Port" },
  { key: "login", title: "Login" },
  { key: "passw", title: "Pass" },
  { key: "isp_name", title: "ISP Name" },
  { key: "ip_check_interval", title: "IP check interval (minutes)" },
  { key: "force_public_ip_match", title: "Force public IP match" },
  { key: "force_local_ip_match", title: "Force local IP match" }
];
export const aas = [
  { key: "total_accs", title: "Total Acccounts" },
  { key: "assign_from_alias", title: "Assign from Alias" }
];
