import React, { useState } from "react";
import { Container, Row, Col, Button } from "reactstrap";
import { useQuery } from "@apollo/react-hooks";
import { GETSCRIPTSETTINGS } from "../SettingsGraphQL";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import ScriptForm from "./components/ScriptForm/index";
import RenderDialogBox from "../../../common/RenderDialogBox";
import { DELETE_SCRIPT_SETTING } from "../SettingsGraphQL";
import { useMutation } from "react-apollo";
import {
  ToastsContainer,
  ToastsStore,
  ToastsContainerPosition
} from "react-toasts";
import { os, ms, rs, aas } from "./constant";
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";

const Script = () => {
  const { data, loading, error, refetch } = useQuery(GETSCRIPTSETTINGS);

  const [deleteScript] = useMutation(DELETE_SCRIPT_SETTING);
  const [isOpenForm, handleForm] = useState(false);
  const [selectedItem, setSelectedItem] = useState(undefined);
  const [isOpenWarningBox, setWarningBox] = useState(false);
  const handleClickonYes = async () => {
    const { id } = selectedItem;
    try {
      await deleteScript({ variables: { id: parseInt(id) } });
      toastMessgage("success", "Delete script!");
      refetch();
      setWarningBox(false);
    } catch (e) {
      toastMessgage("error", e.message);
    }
  };
  const addNew = () => {
    setSelectedItem(undefined);
    handleForm(true);
  };
  const editScriptItem = item => {
    setSelectedItem(item);
    handleForm(true);
  };
  const renderScriptList = () => {
    const settingList = data.getScriptSettings;
    if (settingList.length === 0) {
      return " There is no script setting available";
    }
    return settingList.map(item => {
      return (
        <RenderItem
          item={item}
          key={item.id}
          setWarningBox={setWarningBox}
          setSelectedItem={setSelectedItem}
          editScriptItem={editScriptItem}
        />
      );
    });
  };

  const toastMessgage = (type, msg) => {
    if (type === "success") {
      ToastsStore.success(msg);
    } else {
      ToastsStore.error(msg);
    }
  };

  const renderList = () => {
    if (loading) return <div>{`Loading...`}</div>;
    if (error) return <div>{`Something went wrong`}</div>;
    return (
      <>
        <div className="content-wrapper">
          <div className="scriptContentWrapper">{renderScriptList()}</div>
          <ScriptForm
            isOpen={isOpenForm}
            closeFormModel={handleForm}
            refetch={refetch}
            selectedItem={selectedItem}
            setSelectedItem={setSelectedItem}
            toastMessgage={toastMessgage}
          />

          <RenderDialogBox
            isOpen={isOpenWarningBox}
            handleDialogBox={setWarningBox}
            btnOne={"Yes"}
            btnTwo={"No"}
            message={"Are sure you want to remove setting?"}
            handleClickonYes={handleClickonYes}
          />
        </div>
        <ToastsContainer
          position={ToastsContainerPosition.TOP_CENTER}
          store={ToastsStore}
        />
      </>
    );
  };
  return (
    <>
      <div className="page-details-substripe">
        <Container fluid={true}>
          <Row className="justify-content-between align-items-center">
            <Col>
              <h2 className="stripe-heading m-0 font-weight-500">Config</h2>
            </Col>
            <Col xs="auto">
              <small className="text-muted"></small>
            </Col>
            <Button
              size="sm"
              className={"btn-add-setting"}
              color="primary"
              onClick={addNew}
            >
              Add config
            </Button>
          </Row>
        </Container>
      </div>
      {renderList()}
    </>
  );
};
export default Script;

const booleanValueRender = value => {
  return value ? (
    <AiOutlineCheck className="positiveValue" />
  ) : (
    <AiOutlineClose className="negativeValue" />
  );
};

const booleanSettings = {
  os_allow_digital_fifa_owners_to_play: true,
  log_in_to_webapp_daily: true,
  os_asian_mode: true,
  ai: true,
  os_quit_if_servers_unreachable: true,
  server_issue_throttling: true,
  allow_auto_mulling: true,
  force_public_ip_match: true,
  force_local_ip_match: true,
  objectives: true,
  allow_tm_unlocked_to_play: true,
  pack_preview: true
};

const otherSettingsValueRender = (item, key) => {
  return booleanSettings[key] ? booleanValueRender(item[key]) : item[key];
};

const RenderItem = props => {
  const { item, setWarningBox, setSelectedItem, editScriptItem } = props;
  const [isExpanded, setExpanded] = useState(false);

  const renderMS = () => {
    return ms.map((msItem, index) => {
      return (
        <Row key={"mss" + index}>
          <Col sm="10" className="pt-3 pt-2 font-weight-500">
            {msItem.title}
          </Col>
          <Col sm="2" className="pt-3 pt-2 pl-0">
            {otherSettingsValueRender(item, msItem.key)}
          </Col>
        </Row>
      );
    });
  };
  const renderOS = () => {
    return os.map((osItem, index) => {
      return (
        <Row key={"oss" + index}>
          <Col sm="6" className="pt-3 pt-2 font-weight-500">
            {osItem.title}
          </Col>
          <Col sm="6" className="pt-3 pt-2 pl-0">
            {otherSettingsValueRender(item, osItem.key)}
          </Col>
        </Row>
      );
    });
  };
  const renderAas = () => {
    return aas.map((osItem, index) => {
      return (
        <Row key={"oss" + index}>
          <Col sm="6" className="pt-3 pt-2 font-weight-500">
            {osItem.title}
          </Col>
          <Col sm="6" className="pt-3 pt-2 pl-0">
            {otherSettingsValueRender(item, osItem.key)}
          </Col>
        </Row>
      );
    });
  };
  const renderRS = () => {
    return rs.map((rsItem, index) => {
      return (
        <Row key={"oss" + index}>
          <Col sm="6" className="pt-3 pt-2 font-weight-500">
            {rsItem.title}
          </Col>
          <Col sm="6" className="pt-3 pt-2 pl-0">
            {otherSettingsValueRender(item, rsItem.key)}
          </Col>
        </Row>
      );
    });
  };
  const removeItem = event => {
    event.stopPropagation();
    setWarningBox(true);
    setSelectedItem(item);
  };
  const editItem = event => {
    event.stopPropagation();
    editScriptItem(item);
  };

  return (
    <>
      <div className="script-row">
        <Row
          className="py-2 align-items-center"
          onClick={() => setExpanded(!isExpanded)}
        >
          <Col className="font-weight-500">{item.name}</Col>
          <Col sm="auto">
            <Button color="link" onClick={event => editItem(event)}>
              <FontAwesomeIcon icon={faPencilAlt} className="fa-fw" />
            </Button>
          </Col>
          <Col sm="auto">
            <Button color="link" onClick={event => removeItem(event)}>
              <FontAwesomeIcon icon={faTrash} className="fa-fw text-danger" />
            </Button>
          </Col>
        </Row>
      </div>
      {isExpanded ? (
        <div className="script-setting-list px-3">
          <Row>
            <Col sm="3" className="pt-3">
              <h6 className="script-row-title">
                <span>Matching Settings</span>
              </h6>
              {renderMS()}
            </Col>
            <Col sm="6" className="pt-3 bg-light">
              <h6 className="script-row-title">
                <span>Acc_assignment_settings</span>
              </h6>
              {renderAas()}
              <br />
              <h6 className="script-row-title">
                <span>Other Settings</span>
              </h6>
              {renderOS()}
            </Col>
            <Col sm="3" className="pt-3 bg-light">
              <h6 className="script-row-title">
                <span>Router Settings</span>
              </h6>
              {renderRS()}
            </Col>
          </Row>
          {/* 
          <Row className="script-row-item">
            <Col sm="3"> {renderMS()} </Col>
            <Col sm="6" className="bg-light pb-4">
              {renderOS()}
            </Col>
            <Col sm="3"> </Col>
          </Row> */}
        </div>
      ) : (
        ""
      )}
    </>
  );
};
