import React, { useState, useEffect, useRef } from "react";
import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner
} from "reactstrap";
import { useMutation } from "@apollo/react-hooks";
import { useQuery } from "@apollo/react-hooks";
import {
  GETCONSOLEFORUSER,
  GETCONSOLEFORADMIN,
  UPDATECONSOLE,
  UPDATEMULTIPLECONSOLES,
  GETGRIDVIEWFILTER,
  ADDGRIDVIEWFILTER,
  DELETEGRIDVIEWFILTER,
  EAREG,
  STEAMREG,
  ACTION,
  PSNTASK,
  BANCHECK,
  PAIRING,
  STARTBROWSER
} from "./ConfigurationGraphQL";
import { useSelector, useDispatch } from "react-redux";
import { remove, without, isEmpty } from "lodash";
import Grid from "./components/grid";
import ApiClient from "./components/apiClient";
import { addFilterInStore } from "./ConfigurationActions";
import {
  ToastsContainer,
  ToastsStore,
  ToastsContainerPosition
} from "react-toasts";
import { client } from "../../reducers/config";
import { columnsForAutoResize } from "../tableConfig";
import SaveFilterModal from "./components/Modals/SaveFilterModal";
import ActionModals from "./components/Modals";
import { RESPONSE_STATUSES } from "../../utils/constants";
import localforage from "localforage";

export const gridStateStorage = localforage.createInstance({
  driver: localforage.INDEXEDDB,
  name: "consolebot",
  storeName: "grid"
});

const addFiltersFromServer = (incomingFilters, current) => {
  const filterObj = {};
  const removeObj = {};
  current.forEach((el, index) => {
    filterObj[el.id] = true;
    removeObj[el.id] = index;
  });
  const newFilters = [...current];
  incomingFilters.forEach(el => {
    if (!removeObj[el.id]) {
      const { filter, filtername, id } = el;
      newFilters.push({
        name: filtername,
        id,
        isRemoveable: true,
        filter: JSON.parse(filter)
      });
      removeObj[el.id] = null;
    } else {
      newFilters[removeObj[el.id]].filter = JSON.parse(el.filter);
      removeObj[el.id] = null;
    }
  });
  return newFilters.filter(
    (el, index) => !el.id || (index && el.id && !removeObj[el.id])
  );
};

const initialIndex = Math.round(Math.random() * 100) + 7;

const Configuration = () => {
  const subsciptionIndex = useRef(initialIndex);

  const getQuery = () => {
    const user = JSON.parse(localStorage.getItem("consolebot.user"));
    if (user && user.is_super_admin) {
      subsciptionIndex.current = 2;
      return GETCONSOLEFORADMIN;
    }
    return GETCONSOLEFORUSER;
  };
  const incomingFiltersAdded = useRef(false);
  const defaultFilterSet = useRef(false);
  const [updateConsole] = useMutation(UPDATECONSOLE);
  const [updateMultipleConsoles] = useMutation(UPDATEMULTIPLECONSOLES);
  const { loading, data, refetch } = useQuery(getQuery());
  const [consoleData, setConsoleData] = useState(undefined);
  const [gridApi, setGridApi] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [multipleChangeDialog, setMultipleChangeDialog] = useState(() => null);
  const [actionModal, setActionModal] = useState(() => {});
  const [eaRegTask] = useMutation(EAREG);
  const [steamRegTask] = useMutation(STEAMREG);
  const [startBrowserTask] = useMutation(STARTBROWSER);
  const [action] = useMutation(ACTION);
  const [psnTask] = useMutation(PSNTASK);
  const [banCheck] = useMutation(BANCHECK);
  const [pairing] = useMutation(PAIRING);

  // ---FILTERS VIEW STUFF:
  const filters = useSelector(state => state.configurationReducer.filter);
  const isSelected = useSelector(
    state => state.configurationReducer.isSelected
  );
  const setting = useSelector(state => state.settingsReducer.setting);
  let captcha_key = undefined;
  if (setting) {
    if (setting.id) {
      captcha_key = setting.captcha_key;
    }
  }

  const dispatch = useDispatch();
  const { data: incomingFilters } = useQuery(GETGRIDVIEWFILTER, {
    fetchPolicy: "network-only"
  });
  const [saveViewModal, setSaveViewModal] = useState(false);
  let [filterList, setFilterList] = useState(filters);
  const [saveLoading, setSaveLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars

  const [addGridViewFilter] = useMutation(ADDGRIDVIEWFILTER);
  const [deleteGridViewFilter] = useMutation(DELETEGRIDVIEWFILTER);
  const toggle = () => {
    setSaveViewModal(prev => !prev);
  };

  useEffect(() => {
    if (
      !incomingFiltersAdded.current &&
      incomingFilters?.getGridViewFilter?.length
    ) {
      setFilterList(prev => {
        const newFilters = addFiltersFromServer(
          incomingFilters.getGridViewFilter,
          prev
        );

        return newFilters;
      });
      incomingFiltersAdded.current = true;
    }

    // eslint-disable-next-line
  }, [incomingFilters]);

  const gridRef = !isEmpty(gridApi)
    ? {
        api: gridApi,
        columnApi: gridApi.columnModel
      }
    : null;

  const loadDefaultFilter = () => {
    if (filterList) {
      let selectedFilter =
        isSelected && filterList.find(el => el.name === isSelected);
      if (selectedFilter) {
        setFilter(selectedFilter);
        setFilterList(prev =>
          prev.map(el => ({ ...el, isSelected: el.name === isSelected }))
        );
      } else {
        selectedFilter = filterList.find(el => el.isSelected);
        if (selectedFilter) {
          setFilter(selectedFilter);
        }
      }
    }
  };

  const removeFilter = async (chip, index) => {
    const filterLst = [...filterList];
    filterLst.splice(index, 1);
    dispatch(addFilterInStore(filterLst));
    deleteFilterFromDb(parseInt(chip.id));
    let filterData = await client.readQuery({ query: GETGRIDVIEWFILTER });
    filterData = without(filterData, chip);
    await client.writeQuery({
      query: GETGRIDVIEWFILTER,
      data: {
        getGridViewFilter: [...filterData]
      }
    });
    setFilterList(filterLst);
  };

  const saveState = async name => {
    await setSaveLoading(() => true);
    let expandedRowKey = {};
    gridRef.api.forEachNode(node => {
      if (node.expanded && node.field) {
        if (!expandedRowKey[node.field])
          expandedRowKey[node.field] = [
            { key: node.key, rowGroupIndex: node.rowGroupIndex }
          ];
        else {
          expandedRowKey[node.field].push({
            key: node.key,
            rowGroupIndex: node.rowGroupIndex
          });
        }
      }
    });
    const newFilterState = {};
    newFilterState.colState = gridRef.columnApi.getColumnState();
    newFilterState.rowGroupColums = gridRef.columnApi
      .getRowGroupColumns()
      .map(col => col.colId);
    newFilterState.groupState = gridRef.columnApi.getColumnGroupState();
    newFilterState.filterState = gridRef.api.getFilterModel();
    newFilterState.expandedRowKey = expandedRowKey;
    const filterLst = filterList.map(item => {
      return {
        ...item,
        isSelected: false
      };
    });
    const addedRec = await saveFilterIntoDb(
      name,
      JSON.stringify(newFilterState)
    );
    filterLst.push({
      name,
      filter: newFilterState,
      isSelected: true,
      isRemoveable: true,
      id: addedRec.id
    });
    setFilterList(filterLst);
    toggle();
    dispatch(addFilterInStore(filterLst));
    await setSaveLoading(() => false);
  };

  const restoreState = (filterState, index) => {
    setFilter(filterState);
    const filterLst = filterList.map((item, itemIndex) => {
      return {
        ...item,
        isSelected: itemIndex === index
      };
    });
    setFilterList(filterLst);
    dispatch(addFilterInStore(filterLst));
  };

  const setFilter = filterState => {
    if (gridRef) {
      const { filter } = filterState;
      const hideColumnFilter = {};
      const columnProps = {};
      filter.colState.forEach(col => {
        const { hide, colId, pinned } = col;
        if (!hide) hideColumnFilter[colId] = true;
        columnProps[colId] = { pinned };
      });
      gridRef.columnApi.setRowGroupColumns(filter.rowGroupColums);
      gridRef.columnApi.setColumnGroupState(filter.groupState);
      const currentState = gridRef.columnApi.getColumnState();
      const newState = currentState.map(el => ({
        ...el,
        ...columnProps[el.colId],
        hide: !hideColumnFilter[el.colId]
      }));
      gridRef.columnApi.applyColumnState({
        state: newState,
        applyOrder: true
      });

      gridRef.api.setFilterModel(filter.filterState);
      if (!isEmpty(filter.expandedRowKey)) {
        gridRef.api.forEachNode(el => {
          if (filter.expandedRowKey[el.field])
            if (
              filter.expandedRowKey[el.field].length &&
              Array.isArray(filter.expandedRowKey[el.field]) &&
              filter.expandedRowKey[el.field].find(
                e => e.key === el.key && e.rowGroupIndex === el.rowGroupIndex
              )
            )
              gridRef.api.setRowNodeExpanded(el, true);
        });
      }

      setTimeout(
        () => gridRef.columnApi.autoSizeColumns(columnsForAutoResize),
        200
      );
    }
  };

  const saveFilterIntoDb = async (filtername, filter) => {
    const customer_id = parseInt(localStorage.getItem("consolebot.userId"));
    const data = {
      customer_id,
      filtername,
      filter
    };
    const response = await addGridViewFilter({
      variables: { consoleDetails: data }
    });
    return response.data.addGridViewFilter;
  };

  const deleteFilterFromDb = async id => {
    await deleteGridViewFilter({ variables: { id } });
  };

  useEffect(() => {
    if (!defaultFilterSet.current && gridRef) {
      loadDefaultFilter();
      defaultFilterSet.current = true;
      setTimeout(() => loadGridState(), 10);
    }
    // eslint-disable-next-line
  }, [filterList, gridRef]);

  //----- THE END OF CUSTOM FILTERING-0---

  const toastMessgage = (type, msg) => {
    if (type === "success") {
      ToastsStore.success(msg);
    } else if (type === "info") {
      ToastsStore.info(msg);
    } else {
      ToastsStore.error(msg);
    }
  };

  useEffect(() => {
    if (data && data.getConsole) {
      setConsoleData(data.getConsole);
      setLoading(false);
    }
  }, [data]);

  const deleteRecord = async idList => {
    let consData = [...consoleData];
    remove(consData, item => {
      return idList.includes(parseInt(item.id));
    });
    setConsoleData(consData);
    await client.readQuery({ query: getQuery() });
    // Write back to the to-do list and include the new item
    await client.writeQuery({
      query: getQuery(),
      data: {
        getConsole: [...consData]
      }
    });
    const selectedData = gridApi.getSelectedRows();
    gridApi.updateRowData({ remove: selectedData });
  };

  const fetchRecord = async () => {
    setLoading(true);
    await refetch();
    setLoading(false);
  };

  const startMultipleChange = changedData => {
    setMultipleChangeDialog(() => changedData);
  };

  const cancelMultipleChanges = () => {
    setMultipleChangeDialog(() => null);
  };

  const saveCurrentGridState = () => {
    const user = JSON.parse(localStorage.getItem("consolebot.user"));
    const { id } = user || {};
    if (id && gridRef) {
      const expandedRowKey = {};
      gridRef.api.forEachNode(node => {
        if (node.expanded && node.field) {
          if (!expandedRowKey[node.field])
            expandedRowKey[node.field] = [
              { key: node.key, rowGroupIndex: node.rowGroupIndex }
            ];
          else {
            expandedRowKey[node.field].push({
              key: node.key,
              rowGroupIndex: node.rowGroupIndex
            });
          }
        }
      });
      const newFilterState = {};
      newFilterState.colState = gridRef.columnApi.getColumnState();
      newFilterState.rowGroupColums = gridRef.columnApi
        .getRowGroupColumns()
        .map(col => col.colId);
      newFilterState.groupState = gridRef.columnApi.getColumnGroupState();
      newFilterState.filterState = gridRef.api.getFilterModel();
      newFilterState.expandedRowKey = expandedRowKey;
      const firstDisplayedRow = gridRef.api.getFirstDisplayedRow();
      const lastDisplayedRow = gridRef.api.getLastDisplayedRow();

      if (firstDisplayedRow) {
        newFilterState.visibleRow = Math.round(
          firstDisplayedRow + (lastDisplayedRow - firstDisplayedRow) / 2
        );
      }
      gridStateStorage.setItem(id, newFilterState);
    }
  };

  const loadGridState = async () => {
    const user = JSON.parse(localStorage.getItem("consolebot.user"));
    const { id } = user || {};
    if (id && gridRef) {
      const currentGridConfig = await gridStateStorage.getItem(id);
      if (currentGridConfig) {
        const {
          colState,
          rowGroupColums,
          groupState,
          filterState,
          expandedRowKey
        } = currentGridConfig;
        const hideColumnFilter = {};
        const columnProps = {};
        colState.forEach(col => {
          const { hide, colId, pinned } = col;
          if (!hide) hideColumnFilter[colId] = true;
          columnProps[colId] = { pinned };
        });
        gridRef.columnApi.setRowGroupColumns(rowGroupColums);
        gridRef.columnApi.setColumnGroupState(groupState);
        // const currentState = gridRef.columnApi.getColumnState();
        // const newState = currentState.map(el => ({
        //   ...el,
        //   ...columnProps[el.colId],
        //   hide: !hideColumnFilter[el.colId]
        // }));
        gridRef.columnApi.applyColumnState({
          state: colState,
          applyOrder: true
        });
        gridRef.api.setFilterModel(filterState);
        if (!isEmpty(expandedRowKey)) {
          gridRef.api.forEachNode(el => {
            if (expandedRowKey[el.field])
              if (
                expandedRowKey[el.field].length &&
                Array.isArray(expandedRowKey[el.field]) &&
                expandedRowKey[el.field].find(
                  e => e.key === el.key && e.rowGroupIndex === el.rowGroupIndex
                )
              )
                gridRef.api.setRowNodeExpanded(el, true);
          });
        }

        if (currentGridConfig.visibleRow) {
          gridRef.api.ensureIndexVisible(currentGridConfig.visibleRow);
        }
      }
    }
  };

  const applyMultipleChanges = async () => {
    try {
      const response = await updateMultipleConsoles({
        variables: {
          data: Object.keys(multipleChangeDialog.consoles).map(key => ({
            id: key,
            values: multipleChangeDialog.consoles[key]
          }))
        }
      });
      const ans = response?.data?.updateMultipleConsoles;
      if (ans && ans.result && ans.result === RESPONSE_STATUSES.SUCCESS) {
        toastMessgage("success", "Done");
      } else if (ans && ans.error) {
        toastMessgage("error", ans.error);
      } else {
        toastMessgage("error", "Something went wrong!");
      }
      setMultipleChangeDialog(() => null);
    } catch (e) {
      toastMessgage("error", e.message);
    }
  };

  const customFilterProps = {
    toggle,
    filterList,
    restoreState,
    removeFilter
  };

  const mutations = {
    eaRegTask,
    steamRegTask,
    action,
    psnTask,
    banCheck,
    pairing,
    startBrowserTask
  };

  const actionButtonProps = { mutations, setActionModal, captcha_key };

  const actionModalProps = {
    gridApi,
    toastMessgage,
    setActionModal,
    deleteRecord
  };

  const renderGrid = () => {
    if (loading || isLoading) {
      return <div style={{ marginLeft: 60, marginTop: 5 }}>Loading...</div>;
    } else {
      return (
        <>
          <ApiClient
            gridApi={gridApi}
            subsciptionIndex={subsciptionIndex.current}
          />
          <Grid
            toastMessgage={toastMessgage}
            consoleData={consoleData || []}
            setGridApi={setGridApi}
            updateConsole={updateConsole}
            startMultipleChange={startMultipleChange}
            customFilterProps={customFilterProps}
            actionButtonProps={actionButtonProps}
            saveCurrentGridState={saveCurrentGridState}
            // loadGridState={loadGridState}
          />
        </>
      );
    }
  };

  return (
    <main role="main" className="content-block__width ml-auto px-0">
      {multipleChangeDialog && (
        <Modal isOpen={true} centered={true} zIndex={4} role="dialog">
          <ModalHeader tag="h6" className="py-2 text-dark bg-light shadow-sm">
            Multiple Grid Changes
          </ModalHeader>
          <ModalBody className="p-2">
            <p className="text-center">{`Are you sure you want to ${
              multipleChangeDialog.delete ? "delete" : "replace"
            } ${multipleChangeDialog.cells} cells (${
              multipleChangeDialog.rows
            } rows ${multipleChangeDialog.columns} columns)?`}</p>
          </ModalBody>
          <ModalFooter className="d-block">
            <Row className="justify-content-center">
              <Col xs="7" className="pr-0">
                {isLoading ? (
                  <Button
                    color="primary"
                    className="h-100 postion-relative"
                    block
                  >
                    <Spinner size="sm" className="text-white" />
                  </Button>
                ) : (
                  <Button color="primary" block onClick={applyMultipleChanges}>
                    Confirm
                  </Button>
                )}
              </Col>
              <Col>
                <Button color="secondary" block onClick={cancelMultipleChanges}>
                  Cancel
                </Button>
              </Col>
            </Row>
          </ModalFooter>
        </Modal>
      )}
      <div className="content-wrapper">
        <div id="hot-app" className="w-100 configuration-table">
          {renderGrid()}
        </div>
      </div>
      <ToastsContainer
        position={ToastsContainerPosition.TOP_CENTER}
        store={ToastsStore}
      />
      {saveViewModal && (
        <SaveFilterModal
          addFilter={name => saveState(name.filterName)}
          saveLoading={saveLoading}
          toggle={toggle}
        />
      )}
      <ActionModals
        actionModalProps={actionModalProps}
        actionModal={actionModal}
        fetchRecord={fetchRecord}
      />
    </main>
  );
};
export default Configuration;
