import React from "react";
import { Button, Form, Label, Input } from "reactstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { ADDCAPTCHAKEY } from "../../SettingsGraphQL";
import { useMutation } from "@apollo/react-hooks";
import { useToasts } from "react-toast-notifications";
import { useSelector, useDispatch } from "react-redux";
import { addCaptchaKeyinStore } from "../../SettingsActions";

const TwoCaptchKey = () => {
  const setting = useSelector(state => state.settingsReducer.setting);
  const dispatch = useDispatch();
  const [addCaptchaKey] = useMutation(ADDCAPTCHAKEY);
  const { addToast } = useToasts();
  let setId = 0;
  let initialValues = {
    captcha_key: "",
    mm_integration: false,
    muling_customer_id: ""
  };
  if (setting) {
    if (setting.id) {
      setId = setting.id;
      initialValues = {
        captcha_key: setting.captcha_key,
        muling_customer_id: setting.muling_customer_id,
        mm_integration: setting.mm_integration
      };
    }
  }

  initialValues.mm_integration_ = initialValues.mm_integration;

  let isSubmit = false;

  const submit = async values => {
    if (isSubmit === false) {
      isSubmit = true;
      const variables = { id: parseInt(setId), ...values };
      try {
        const response = await addCaptchaKey({ variables });
        let sett = { ...setting };
        sett.captcha_key = values.captcha_key;
        sett.id = response.data.addCaptchaKey.id;
        sett.mm_integration = values.mm_integration;
        sett.muling_customer_id = values.muling_customer_id;
        dispatch(addCaptchaKeyinStore(sett));
        addToast("Key updated", {
          appearance: "success",
          autoDismiss: true
        });
        isSubmit = false;
      } catch (e) {
        addToast(e.message, {
          appearance: "error",
          autoDismiss: true
        });
        isSubmit = false;
      }
    }
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        onSubmit={async values => {
          submit(values);
        }}
        validationSchema={Yup.object().shape({
          captcha_key: Yup.string().required("Required")
        })}
      >
        {props => {
          const {
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit
          } = props;
          return (
            <Form onSubmit={handleSubmit}>
              <div className="global-form">
                <div>
                  <Label for="captcha_key">2captcha Key (For acc reg):</Label>
                  <Input
                    id="captcha_key"
                    placeholder="enter captcha_key"
                    type="text"
                    value={values.captcha_key}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={
                      errors.captcha_key && touched.captcha_key
                        ? "text-input error"
                        : "text-input"
                    }
                    style={{ minWidth: 240, width: 240, marginRight: 15 }}
                  />
                  {errors.captcha_key && touched.captcha_key && (
                    <div className="input-feedback">{errors.captcha_key}</div>
                  )}
                  <Label for="muling_customer_id">Muling Customer Id:</Label>
                  <Input
                    id="muling_customer_id"
                    type="text"
                    value={values.muling_customer_id}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={
                      errors.muling_customer_id && touched.muling_customer_id
                        ? "text-input error"
                        : "text-input"
                    }
                    style={{ minWidth: 240, width: 240, marginRight: 15 }}
                  />
                  {errors.muling_customer_id && touched.muling_customer_id && (
                    <div className="input-feedback">
                      {errors.muling_customer_id}
                    </div>
                  )}
                </div>

                <Button
                  color="primary"
                  size="sm"
                  type="submit"
                  disabled={isSubmitting}
                  className="global-submit-button"
                  style={{ marginBottom: 2 }}
                >
                  Save
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default TwoCaptchKey;
