import * as actions from "./ConfigurationActionTypes";

const defaultFilter = {
  colState: [
    {
      colId: "customer_farm_name",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "server",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "alias",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "email",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "password",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "psn_gauth",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "steam_accountname",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "steam_keys",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "psn_backups",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "hwid",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "reg_status",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "dob",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "psn_reg_country",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "firstname",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "lastname",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "ea_email",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "ea_password",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "gauth",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "gauth",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "gauth",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "gauth",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "gauth",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "gauth",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "gauth",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "gauth",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "ea_proxy",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "ea_backups",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "originId",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "ea_securityAnswer",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "country",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "ea_birthday",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "ea_firstname",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "ea_lastname",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "ea_imapusername",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "ea_imappassword",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "ea_imaphost",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "ea_imapport",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "ea_reg_status",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "ea_reg_date",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "status",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "status_message",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "coins",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "sb_points",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "muled",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "fifa_stats",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },

    {
      colId: "description",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "times_tamp",
      hide: false,
      aggFunc: null,
      width: 200,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "script",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "tm_unlocked",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "args",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "config",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "screen_shot_link",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "proxyip",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "proxyport",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "proxy_login",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "proxy_password",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "rotation_link",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "psn_token",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "pairing_status",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "psn_signin_status",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "mac_address",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "license_expiration",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "psnid",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "imap_login",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "imap_password",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "email_web_password",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "imap_host",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "imap_gauth",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "imap_proxy",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "reg_ban",
      hide: false,
      aggFunc: null,
      width: 120,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "muling_ban",
      hide: false,
      aggFunc: null,
      width: 120,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "botting_ban",
      hide: false,
      aggFunc: null,
      width: 120,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "ban_check_status",
      hide: false,
      aggFunc: null,
      width: 120,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "type",
      hide: false,
      aggFunc: null,
      width: 120,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "avatar",
      hide: false,
      aggFunc: null,
      width: 120,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "console_ip",
      hide: false,
      aggFunc: null,
      width: 120,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "console_ip_actual_time",
      hide: false,
      aggFunc: null,
      width: 150,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "public_ip",
      hide: false,
      aggFunc: null,
      width: 120,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "storage",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    }
  ],
  groupState: [
    { groupId: "0", open: false },
    { groupId: "1", open: false },
    {
      groupId: "2",
      open: false
    },
    { groupId: "3", open: false },
    { groupId: "4", open: false },
    {
      groupId: "5",
      open: false
    },
    { groupId: "6", open: false },
    { groupId: "7", open: false }
  ],
  sortState: [],
  filterState: {},
  expandedRowKey: []
};
const pairingView = {
  colState: [
    {
      colId: "customer_farm_name",
      hide: false,
      aggFunc: null,
      width: 111,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "server",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "alias",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "email",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "password",
      hide: true,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "dob",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "ea_email",
      hide: true,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "ea_password",
      hide: true,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "gauth",
      hide: true,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "ea_proxy",
      hide: true,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "status",
      hide: true,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },

    {
      colId: "status_message",
      hide: true,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "coins",
      hide: true,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "sb_points",
      hide: true,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    // {
    //   colId: "muled",
    //   hide: false,
    //   aggFunc: null,
    //   width: 90,
    //   pivotIndex: null,
    //   pinned: null,
    //   rowGroupIndex: null
    // },

    {
      colId: "description",
      hide: true,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "times_tamp",
      hide: true,
      aggFunc: null,
      width: 200,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "script",
      hide: true,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "config",
      hide: true,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "screen_shot_link",
      hide: true,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "proxyip",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "proxyport",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "proxy_login",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "proxy_password",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "rotation_link",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "psn_token",
      hide: true,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "pairing_status",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "psn_signin_status",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "mac_address",
      hide: true,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "license_expiration",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "psnid",
      hide: true,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "imap_login",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "imap_password",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "email_web_password",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "imap_host",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "imap_gauth",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "imap_proxy",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "type",
      hide: false,
      aggFunc: null,
      width: 80,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "console_ip",
      hide: false,
      aggFunc: null,
      width: 120,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "console_ip_actual_time",
      hide: false,
      aggFunc: null,
      width: 150,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "public_ip",
      hide: false,
      aggFunc: null,
      width: 120,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    }
  ],
  groupState: [
    { groupId: "0", open: false },
    { groupId: "1", open: false },
    {
      groupId: "2",
      open: false
    },
    { groupId: "3", open: false },
    { groupId: "4", open: false },
    {
      groupId: "5",
      open: false
    },
    { groupId: "6", open: false }
  ],
  sortState: [],
  filterState: {}
};

const workView = {
  colState: [
    {
      colId: "customer_farm_name",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "server",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "alias",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "type",
      hide: false,
      aggFunc: null,
      width: 300,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "avatar",
      hide: false,
      aggFunc: null,
      width: 300,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "storage",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "email",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "password",
      hide: true,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "ea_email",
      hide: true,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "ea_password",
      hide: true,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "gauth",
      hide: true,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "ea_proxy",
      hide: true,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "status",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "status_message",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "description",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "fifa_stats",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "coins",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "sb_points",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "muled",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },

    {
      colId: "times_tamp",
      hide: false,
      aggFunc: null,
      width: 200,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "script",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "tm_unlocked",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "args",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "config",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "screen_shot_link",
      hide: true,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "proxyip",
      hide: true,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "proxyport",
      hide: true,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "proxy_login",
      hide: true,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "proxy_password",
      hide: true,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "rotation_link",
      hide: true,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "psn_token",
      hide: true,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "pairing_status",
      hide: true,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "psn_signin_status",
      hide: true,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "mac_address",
      hide: true,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "license_expiration",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "psnid",
      hide: true,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "imap_login",
      hide: true,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "imap_password",
      hide: true,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "email_web_password",
      hide: true,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "imap_host",
      hide: true,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "imap_gauth",
      hide: true,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "imap_proxy",
      hide: true,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "console_ip",
      hide: false,
      aggFunc: null,
      width: 120,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "console_ip_actual_time",
      hide: false,
      aggFunc: null,
      width: 150,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "public_ip",
      hide: false,
      aggFunc: null,
      width: 120,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    }
  ],
  groupState: [
    { groupId: "0", open: false },
    { groupId: "1", open: false },
    {
      groupId: "2",
      open: false
    },
    { groupId: "3", open: false },
    { groupId: "4", open: false },
    {
      groupId: "5",
      open: false
    },
    { groupId: "6", open: false }
  ],
  sortState: [],
  filterState: {}
};

const eaReg = {
  colState: [
    {
      colId: "customer_farm_name",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "server",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "alias",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "ea_email",
      hide: false,
      aggFunc: null,
      width: 130,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "ea_password",
      hide: false,
      aggFunc: null,
      width: 130,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "gauth",
      hide: false,
      aggFunc: null,
      width: 130,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "ea_proxy",
      hide: false,
      aggFunc: null,
      width: 130,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "originId",
      hide: false,
      aggFunc: null,
      width: 130,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "ea_securityAnswer",
      hide: false,
      aggFunc: null,
      width: 130,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "country",
      hide: false,
      aggFunc: null,
      width: 130,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "ea_birthday",
      hide: false,
      aggFunc: null,
      width: 130,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "ea_firstname",
      hide: false,
      aggFunc: null,
      width: 130,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "ea_lastname",
      hide: false,
      aggFunc: null,
      width: 130,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "ea_imapusername",
      hide: false,
      aggFunc: null,
      width: 130,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "ea_imappassword",
      hide: false,
      aggFunc: null,
      width: 130,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "ea_imaphost",
      hide: false,
      aggFunc: null,
      width: 130,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "ea_imapport",
      hide: false,
      aggFunc: null,
      width: 130,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "ea_reg_status",
      hide: false,
      aggFunc: null,
      width: 130,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "ea_reg_date",
      hide: false,
      aggFunc: null,
      width: 130,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "ea_backups",
      hide: false,
      aggFunc: null,
      width: 130,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "proxyip",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "proxyport",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "proxy_login",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "proxy_password",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "rotation_link",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    }
  ],
  groupState: [
    { groupId: "0", open: false },
    { groupId: "1", open: false },
    {
      groupId: "2",
      open: false
    },
    { groupId: "3", open: false },
    { groupId: "4", open: false },
    {
      groupId: "5",
      open: false
    },
    { groupId: "6", open: false },
    { groupId: "7", open: false }
  ],
  sortState: [],
  filterState: {},
  expandedRowKey: []
};
const psnReg = {
  colState: [
    {
      colId: "rp_regist_key",
      hide: true,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "rp_key",
      hide: true,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "customer_farm_name",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "server",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "alias",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "email",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "password",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "psn_gauth",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "steam_accountname",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "steam_keys",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "psn_backups",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "hwid",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "reg_status",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "dob",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "psn_reg_country",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "firstname",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "lastname",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "ea_email",
      hide: true,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "ea_password",
      hide: true,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "gauth",
      hide: true,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "ea_proxy",
      hide: true,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "status",
      hide: true,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "status_message",
      hide: true,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "coins",
      hide: true,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "sb_points",
      hide: true,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "muled",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "description",
      hide: true,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "times_tamp",
      hide: true,
      aggFunc: null,
      width: 200,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "script",
      hide: true,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "config",
      hide: true,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "screen_shot_link",
      hide: true,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "proxyip",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "proxyport",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "proxy_login",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "proxy_password",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "rotation_link",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "psn_token",
      hide: true,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "pairing_status",
      hide: true,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "psn_signin_status",
      hide: true,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "mac_address",
      hide: true,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "license_expiration",
      hide: true,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "psnid",
      hide: true,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "imap_login",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "imap_password",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "email_web_password",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "imap_host",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "imap_gauth",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "imap_proxy",
      hide: false,
      aggFunc: null,
      width: 90,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "console_ip",
      hide: false,
      aggFunc: null,
      width: 120,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "console_ip_actual_time",
      hide: false,
      aggFunc: null,
      width: 150,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "public_ip",
      hide: false,
      aggFunc: null,
      width: 120,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    }
  ],
  groupState: [
    { groupId: "0", open: false },
    { groupId: "1", open: false },
    {
      groupId: "2",
      open: false
    },
    { groupId: "3", open: false },
    { groupId: "4", open: false },
    {
      groupId: "5",
      open: false
    },
    { groupId: "6", open: false },
    { groupId: "7", open: false }
  ],
  sortState: [],
  filterState: {},
  expandedRowKey: []
};
const banCheck = {
  colState: [
    {
      colId: "customer_farm_name",
      hide: false,
      aggFunc: null,
      width: 100,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "server",
      hide: false,
      aggFunc: null,
      width: 100,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "alias",
      hide: false,
      aggFunc: null,
      width: 100,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "email",
      hide: false,
      aggFunc: null,
      width: 250,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "ea_email",
      hide: false,
      aggFunc: null,
      width: 250,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "ea_reg_status",
      hide: false,
      aggFunc: null,
      width: 200,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "ea_reg_date",
      hide: false,
      aggFunc: null,
      width: 200,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "reg_ban",
      hide: false,
      aggFunc: null,
      width: 300,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "muling_ban",
      hide: false,
      aggFunc: null,
      width: 300,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "botting_ban",
      hide: false,
      aggFunc: null,
      width: 300,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    },
    {
      colId: "ban_check_status",
      hide: false,
      aggFunc: null,
      width: 300,
      pivotIndex: null,
      pinned: null,
      rowGroupIndex: null
    }
  ],
  groupState: [
    { groupId: "0", open: false },
    { groupId: "1", open: false },
    {
      groupId: "2",
      open: false
    },
    { groupId: "3", open: false },
    { groupId: "4", open: false },
    {
      groupId: "5",
      open: false
    },
    { groupId: "6", open: false },
    { groupId: "7", open: false }
  ],
  sortState: [],
  filterState: {},
  expandedRowKey: []
};
const filters = [
  {
    name: "All",
    filter: defaultFilter,
    isSelected: true,
    isRemoveable: false
  },
  {
    name: "Pairing",
    filter: pairingView,
    isSelected: false,
    isRemoveable: false
  },
  { name: "Work", filter: workView, isSelected: false, isRemoveable: false },
  {
    name: "PSN Reg",
    filter: psnReg,
    isSelected: false,
    isRemoveable: false
  },
  {
    name: "EA Reg",
    filter: eaReg,
    isSelected: false,
    isRemoveable: false
  },
  {
    name: "Check bans",
    filter: banCheck,
    isSelected: false,
    isRemoveable: false
  }
];
export const initialState = {
  filter: filters
};

const configurationReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.CLEAR_FILTER:
      return { ...state, filter: initialState.filter };
    case actions.ADD_FILTER: {
      const selected = action.payload.find(el => el.isSelected);
      return {
        ...state,
        filter: [...action.payload],
        isSelected: selected ? selected.name : state.isSelected
      };
    }
    default:
      return state;
  }
};

export default configurationReducer;
