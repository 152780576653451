import React, { PureComponent } from "react";
import { adminColumnDefs, userColumnDefs } from "../../tableConfig";
import { getContextMenuItems } from "../../tableConfig/gridContextMenu";
import DatePickerRender from "./datePickerRender";
import StatusBarCoinsCounter from "./GridComponents/StatusBarCoinsCounter";
import StateResetButton from "./GridComponents/StateResetButton";
import { debounce } from "throttle-debounce";
import { AgGridReact } from "ag-grid-react";
import { isEmpty } from "lodash";

const uneditableFields = {
  times_tamp: true,
  mac_address__: true,
  license_expiration: true,
  muled: true
};

const isSelectedRange = api => {
  const selectedRange = api.getCellRanges();
  let isRange = false;
  if (
    selectedRange &&
    selectedRange.length &&
    (selectedRange.length > 1 ||
      selectedRange[0].startRow.rowIndex !== selectedRange[0].endRow.rowIndex ||
      selectedRange[0].columns.length > 1)
  )
    isRange = true;
  return isRange;
};

export default class Grid extends PureComponent {
  state = {
    gridRef: undefined,
    gridApi: undefined,
    rowData: []
  };
  data = [];
  interval = 0;
  isRangeToPaste = false;
  changedData = { columns: {}, rows: {}, consoles: {}, cells: 0 };

  onLoadGrid = params => {
    const { setGridApi, consoleData } = this.props;
    this.data = [...consoleData];
    params.api.setRowData(this.data);
    setGridApi(params.api);
    // setTimeout(() => loadGridState(), 10);
    this.setState({ gridApi: params.api, gridRef: params });
  };

  onCellValueChanged = async params => {
    const { field } = params.colDef;
    if (uneditableFields[field] || this.isRangeToPaste) {
      return params.oldValue;
    }
    if (params.value === undefined && !params.oldValue) {
      //do nothing
    } else if (params.value !== params.oldValue) {
      const { updateConsole, toastMessgage } = this.props;
      let value = params.value;
      const { field } = params.colDef;
      if (params.colDef.field === "coins") {
        value = parseInt(value);
        if (isNaN(value)) {
          value = 0;
        }
      }
      const data = {
        field,
        value: value === undefined ? "" : value,
        id: params.data.id
      };
      try {
        const response = await updateConsole({ variables: { data } });
        const ans = response?.data?.updateConsole;
        if (ans && ans.success) {
          toastMessgage("success", "Done");
        } else if (ans && ans.error) {
          toastMessgage("error", ans.error);
          return params.oldValue;
        } else {
          toastMessgage("error", "Something went wrong!");
          return params.oldValue;
        }
      } catch (e) {
        toastMessgage("error", e.message);
      }
    }
  };

  onSelectionChanged = () => {
    const { gridApi } = this.state;
    const selectedRows = gridApi.getSelectedRows();
    let selection = false;
    if (selectedRows.length > 0) {
      selection = true;
    }
    this.selectionChanged = selection;
  };

  onFiltered = () => {
    const { gridApi } = this.state;
    gridApi.deselectAll();
  };

  processDataFromClipboard = params => {
    const { data } = params;
    if (
      data.length > 1 ||
      data[0].length > 1 ||
      isSelectedRange(this.state.gridApi)
    ) {
      this.isRangeToPaste = true;
    }
    return params.data;
  };

  processCellFromClipboard = params => {
    const { column, node, value } = params;
    const colId = column.getId();
    if (this.isRangeToPaste) {
      this.changedData.columns[colId] = true;
      this.changedData.cells += 1;
      this.changedData.rows[node.rowIndex] = true;
      const { id } = node.data;
      this.changedData.consoles[id] = this.changedData.consoles[id]
        ? { ...this.changedData.consoles[id], [colId]: value }
        : { [colId]: value };
      return node.data[colId];
    }
    if (!uneditableFields[colId]) {
      return value;
    }
    return node.data[colId];
  };

  onPasteStart = () => {
    if (this.isRangeToPaste) {
      const { columns, rows, consoles, cells } = this.changedData;
      this.props.startMultipleChange({
        columns: Object.keys(columns).length,
        rows: Object.keys(rows).length,
        consoles,
        cells
      });
    }
    this.isRangeToPaste = false;
    this.changedData = { columns: {}, rows: {}, consoles: {}, cells: 0 };
  };

  getColumnDef = () => {
    const user = JSON.parse(localStorage.getItem("consolebot.user"));
    if (user && user.is_super_admin) {
      return adminColumnDefs;
    }
    return userColumnDefs;
  };

  getCustomFiltersProps = () => this.props.customFilterProps;
  getActionButtonsProps = () => {
    const { gridApi } = this.state;
    const { actionButtonProps, toastMessgage } = this.props;
    return {
      toastMessgage,
      gridApi,
      isRowSelected: this.selectionChanged,
      ...actionButtonProps
    };
  };

  setRemovedRanges = (start, end, columns, gridApi, rangesCount) => {
    const columnsCount = columns.length;
    if (columnsCount > 1 || end - start > 0 || rangesCount > 1) {
      columns.forEach(colId => (this.changedData.columns[colId] = true));
      const rowsCount = end - start + 1;
      for (let i = start; i <= end; i++) {
        this.changedData.rows[i] = true;
      }
      this.changedData.cells += columnsCount * rowsCount;
      gridApi.forEachNode(node => {
        const {
          data: { id },
          rowIndex
        } = node;
        if (
          typeof rowIndex === "number" &&
          rowIndex >= start &&
          rowIndex <= end
        ) {
          this.changedData.consoles[id] = this.changedData.consoles[id]
            ? { ...this.changedData.consoles[id] }
            : {};
          columns.forEach(colId => (this.changedData.consoles[id][colId] = ""));
        }
      });
    }
  };

  suppressKeyboardEvent = params => {
    if (!params.editing) {
      const isDeleteKey = params.event.keyCode === 46;
      let isRange = false;
      if (isDeleteKey) {
        const ranges = params.api.getCellRanges();
        ranges.forEach(range => {
          const colIds = range.columns.map(col => col.colId);

          const startRowIndex = Math.min(
            range.startRow.rowIndex,
            range.endRow.rowIndex
          );
          const endRowIndex = Math.max(
            range.startRow.rowIndex,
            range.endRow.rowIndex
          );

          this.setRemovedRanges(
            startRowIndex,
            endRowIndex,
            colIds,
            params.api,
            ranges.length
          );
        });
        isRange = !isEmpty(this.changedData.columns);
        if (isRange) {
          const { columns, rows, consoles, cells } = this.changedData;
          this.props.startMultipleChange({
            columns: Object.keys(columns).length,
            rows: Object.keys(rows).length,
            consoles,
            cells,
            delete: true
          });

          this.changedData = { columns: {}, rows: {}, consoles: {}, cells: 0 };
          return true;
        } else return false;
      }
    }
  };

  saveCurrentGridState = () => {
    this.props.saveCurrentGridState();
  };

  gridStateChanged = debounce(1000, e => {
    const { source, type } = e;
    if (
      (source && source.toLowerCase().includes("ui")) ||
      type === "columnPinned" ||
      type === "filterChanged" ||
      type === "columnGroupOpened" ||
      type === "bodyScroll"
    ) {
      this.saveCurrentGridState();
    }
  });

  render() {
    const { rowData } = this.state;
    const { customFilterProps, toastMessgage } = this.props;

    return (
      <>
        <div style={{ width: "100%", height: "100%" }}>
          <div
            id="myGrid"
            style={{
              height: "100vh",
              width: "100%"
            }}
            key="myGrid"
            className="ag-theme-balham"
          >
            <AgGridReact
              key={"AgGridReact"}
              asyncTransactionWaitMillis={1000}
              onGridReady={this.onLoadGrid}
              enableRangeSelection={true}
              customFilterProps={customFilterProps}
              getContextMenuItems={params =>
                getContextMenuItems({
                  ...params,
                  context: {
                    getCustomFiltersProps: this.getCustomFiltersProps,
                    getActionButtonsProps: this.getActionButtonsProps
                  },
                  toastMessgage
                })
              }
              defaultColDef={{
                filter: true,
                resizable: true,
                sortable: true,
                editable: true,
                suppressKeyboardEvent: this.suppressKeyboardEvent
              }}
              columnDefs={this.getColumnDef()}
              rowData={rowData}
              rowSelection="multiple"
              rowGroupPanelShow="always"
              animateRows={false}
              suppressRowClickSelection={false}
              overlayLoadingTemplate={
                '<span className="ag-overlay-loading-center">Please wait while your rows are loading</span>'
              }
              ensureDomOrder={true}
              suppressReactUi={true}
              sideBar={true}
              undoRedoCellEditing={true}
              undoRedoCellEditingLimit={50}
              groupSelectsChildren={true}
              enterMovesDownAfterEdit={true}
              onCellValueChanged={this.onCellValueChanged}
              onPasteStart={this.onPasteStart}
              stopEditingWhenCellsLoseFocus={true}
              onSelectionChanged={this.onSelectionChanged}
              onFilterModified={this.onFiltered}
              enableCellChangeFlash={true}
              statusBar={StatusBar}
              getRowId={row => {
                return row.data.id;
              }}
              autoGroupColumnDef={{
                headerName: "Group",
                field: "customer_farm_name",
                width: 200,
                cellRenderer: "agGroupCellRenderer",
                cellRendererParams: { checkbox: true }
              }}
              components={{
                datePickerRender: DatePickerRender,
                statusBarCoinsCounter: StatusBarCoinsCounter,
                stateResetButton: StateResetButton
              }}
              suppressExcelExport={true}
              suppressCsvExport={false}
              processCellFromClipboard={this.processCellFromClipboard}
              processDataFromClipboard={this.processDataFromClipboard}
              multiSortKey="ctrl"
              // ==== Events for current view state saving ====
              onSortChanged={this.gridStateChanged}
              onColumnPinned={this.gridStateChanged}
              onColumnMoved={this.gridStateChanged}
              onColumnVisible={this.gridStateChanged}
              onFilterChanged={this.gridStateChanged}
              onColumnGroupOpened={this.gridStateChanged}
              onColumnRowGroupChanged={this.gridStateChanged}
              onBodyScroll={this.gridStateChanged}
              // ====
            ></AgGridReact>
          </div>
        </div>
      </>
    );
  }
}

const StatusBar = {
  statusPanels: [
    {
      statusPanel: "agTotalAndFilteredRowCountComponent",
      align: "left"
    },
    {
      statusPanel: "statusBarCoinsCounter",
      align: "left"
    },
    { statusPanel: "agFilteredRowCountComponent" },
    { statusPanel: "agSelectedRowCountComponent" },
    { statusPanel: "agAggregationComponent" },
    { statusPanel: "stateResetButton" }
  ]
};
