import React from "react";
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    Label,
    FormGroup,
    Nav,
    NavItem,
    NavLink
} from "reactstrap";
import logoIcon from "./../../assets/images/logo-icon.svg";
import {Link, useHistory} from "react-router-dom";
import {Formik, Form, Field, ErrorMessage} from "formik";
import * as Yup from "yup";
import {useMutation} from "@apollo/react-hooks";
import {LOGIN} from "./LoginGraphQL";
import {useToasts} from "react-toast-notifications";
import {encryption} from "../../utils";

const LoginValidation = Yup.object().shape({
    email: Yup.string().email().required(),
    password: Yup.string().required()
});

const Login = () => {
    const [login] = useMutation(LOGIN);
    let history = useHistory();
    localStorage.clear();
    const {addToast} = useToasts();
    const submit = async values => {
        const {email, password} = values;
        try {
            const loginData = await login({variables: {email, password}});
            console.log(loginData)
            let {user} = loginData.data.login;
            delete user.password;
            localStorage.setItem("consolebot.token", loginData.data.login.token);
            localStorage.setItem("consolebot.userId", loginData.data.login.user.id);
            localStorage.setItem("consolebot.email", loginData.data.login.user.email);
            localStorage.setItem("consolebot.key", encryption(password));
            localStorage.setItem("consolebot.user", JSON.stringify(user));
            console.log(localStorage.getItem("consolebot.user"))
            console.log(localStorage.getItem("consolebot.key"))
            history.push("/overview");
        } catch (e) {
            addToast(e.message, {
                appearance: "error",
                autoDismiss: true
            });
        }
    };
    return (
        <section className="bg-secondary position-relative">
            <div className="position-absolute bookmark-icon">
                <img src={logoIcon} alt=""/>
            </div>
            <Container fluid={true}>
                <Row>
                    <Col sm={3} className="login-bg-image d-sm-block d-none"></Col>
                    <Col sm={9} className="m-auto">
                        <Row className="justify-content-center py-5 min-height-100vh">
                            <Col lg={6} sm={8} xs={10} className="mt-auto">
                                <h5 className="text-white font-weight-bold mb-4 text-center">
                                    Sign in to your account
                                </h5>
                                <Card className="bg-none p-3 rounded-sm login-card">
                                    <CardBody>
                                        <Formik
                                            initialValues={{email: "", password: ""}}
                                            validationSchema={LoginValidation}
                                            onSubmit={submit}
                                        >
                                            {({isSubmitting}) => (
                                                <Form>
                                                    <FormGroup>
                                                        <Label
                                                            size="sm"
                                                            for="exampleEmail"
                                                            className="text-white"
                                                        >
                                                            Email
                                                        </Label>
                                                        <Field
                                                            // bsSize="sm"
                                                            type="email"
                                                            name="email"
                                                            id="exampleEmail"
                                                            placeholder="Enter Email"
                                                            className="form-control"
                                                        />
                                                        <ErrorMessage
                                                            name="email"
                                                            component="div"
                                                            className="text-light small mt-1"
                                                        />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label
                                                            size="sm"
                                                            for="examplePassword"
                                                            className="text-white"
                                                        >
                                                            Password
                                                        </Label>
                                                        <Field
                                                            // bsSize="sm"
                                                            type="password"
                                                            name="password"
                                                            id="examplePassword"
                                                            placeholder="Enter password"
                                                            className="form-control"
                                                        />
                                                        <ErrorMessage
                                                            name="password"
                                                            component="div"
                                                            className="text-light small mt-1"
                                                        />
                                                    </FormGroup>
                                                    <Link
                                                        to=""
                                                        className="d-inline-block small text-white"
                                                    >
                                                        Forgot Password?
                                                    </Link>
                                                    <button
                                                        type="submit"
                                                        disabled={isSubmitting}
                                                        color="primary"
                                                        className="btn-block btn btn-primary d-block py-2 my-3 text-center font-weight-bold rounded-pill text-uppercase"
                                                    >
                                                        Sign In
                                                    </button>
                                                </Form>
                                            )}
                                        </Formik>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col xs={12} className="mb-0">
                                <Nav className="justify-content-center white-links-separator pt-4">
                                    <NavItem>
                                        <NavLink href="#" className="text-white">
                                            Terms and Conditions
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink href="#" className="text-white">
                                            Privacy Policy
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};
export default Login;
