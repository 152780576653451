import { isEmpty } from "lodash";

export const pairingButton = async props => {
  const {
    gridApi,
    toastMessgage,
    mutations: { pairing }
  } = props;

  const selectedNodes = gridApi.getSelectedNodes();
  const pairingList = selectedNodes.map(node => parseInt(node.data.id));
  const username = localStorage.getItem("consolebot.email");

  try {
    const response = await pairing({
      variables: { consoleDetails: pairingList, username }
    });
    const { erroredTypes, result, success } = response.data.pairing;
    if (!isEmpty(erroredTypes)) {
      if (!success) toastMessgage("error", result);
      else toastMessgage("success", result);
      selectedNodes.forEach(node => {
        const erroredStatus = erroredTypes[node.data.id];
        if (erroredStatus) node.setDataValue("reg_status", erroredStatus);
      });
    } else toastMessgage("success", result);
  } catch (e) {
    toastMessgage("error", e.message);
  }
};
