import eaReg from "./actionButtons/eaReg";
import steamReg from "./actionButtons/steamReg";
import startBrowser from "./actionButtons/startBrowser";
import startStop from "./actionButtons/startStop";
import { psnReg, psnSignIn, psnGauth } from "./actionButtons/psnTask";
import banCheckButton from "./actionButtons/banCheck";
import { pairingButton } from "./actionButtons/pairing";
import getCsvSample from "./actionButtons/getCsvSample";
import { ACTION_MODALS } from "../Configuration/components/Modals";
import totp from "totp-generator";

const getEmails = async params => {
  const hostname = params?.node?.data?.imap_proxy;
  const { email, alias, imap_host, imap_login, imap_password } =
    params?.node?.data || {};
  const splitted = hostname.split("#");
  const hostPort = splitted[0].split(":");
  const socksHost = hostPort[1].replace("//", "");
  const socksPort = hostPort[2];
  const loginpass = splitted[1].split(":");
  const socksUser = loginpass[0];
  const socksPass = loginpass[1];
  window.open(
    `${process.env.REACT_APP_IMAP_READ_URL}?email=${email}&alias=${alias}&socksHost=${socksHost}&socksPort=${socksPort}&socksUser=${socksUser}&socksPass=${socksPass}&imap_host=${imap_host}&imap_login=${imap_login}&imap_password=${imap_password}`,
    "_blank",
    "noopener,noreferrer"
  );
};

const getTotp = async (params, toastMessgage) => {
  const cell = params.gridApi.getFocusedCell();
  var row = params.gridApi.getDisplayedRowAtIndex(cell.rowIndex);
  var cellValue = params.gridApi.getValue(cell.column.colId, row);
  try {
    const token = await totp(cellValue);
    toastMessgage("success", token);
  } catch (error) {
    toastMessgage("error", error.message);
  }
};

export const getContextMenuItems = params => {
  const customFilterProps = params.context.getCustomFiltersProps();
  const actionButtonsProps = params.context.getActionButtonsProps();
  const { toggle, filterList, restoreState, removeFilter } = customFilterProps;
  const { isRowSelected, captcha_key, setActionModal } = actionButtonsProps;

  const active =
    filterList && filterList.length
      ? filterList.find(el => el.isSelected)
      : null;
  const result = [
    "copy",
    "copyWithHeaders",
    "paste",
    "separator",
    {
      name: "Actions",
      // icon: '<i class="fa fa-download"/>',
      subMenu: [
        {
          name: "Start",
          disabled: !isRowSelected,
          action: () => {
            startStop({ ...actionButtonsProps, actionType: "start" });
          }
        },
        {
          name: "Stop",
          disabled: !isRowSelected,
          action: () => {
            startStop({ ...actionButtonsProps, actionType: "stop" });
          }
        },
        {
          name: "PSN Reg",
          disabled: !isRowSelected || !captcha_key,
          action: () => {
            psnReg(actionButtonsProps);
          }
        },
        {
          name: "Get TOTP",
          action: () => {
            getTotp(actionButtonsProps, params.toastMessgage);
          }
        },
        {
          name: "EA Reg",
          disabled: !isRowSelected || !captcha_key,
          action: () => {
            eaReg(actionButtonsProps);
          }
        },
        {
          name: "Steam Reg",
          disabled: !isRowSelected || !captcha_key,
          action: () => {
            steamReg(actionButtonsProps);
          }
        },
        {
          name: "Launch Browser",
          disabled: !isRowSelected,
          action: () => {
            startBrowser(actionButtonsProps);
          }
        },
        {
          name: "Ban Check",
          disabled: !isRowSelected,
          action: () => {
            banCheckButton(actionButtonsProps);
          }
        },
        {
          name: "PSN Sign In",
          disabled: !isRowSelected || !captcha_key,
          action: () => {
            psnSignIn(actionButtonsProps);
          }
        },
        {
          name: "PSN Gauth",
          disabled: !isRowSelected || !captcha_key,
          action: () => {
            psnGauth(actionButtonsProps);
          }
        },
        {
          name: "Pairing",
          disabled: !isRowSelected,
          action: () => {
            pairingButton(actionButtonsProps);
          }
        },
        {
          name: "Unpair",
          disabled: !isRowSelected,
          action: () => {
            setActionModal(prev => ({
              ...prev,
              [ACTION_MODALS.UNPAIRING]: true
            }));
          }
        },
        {
          name: "License",
          disabled: !isRowSelected,
          action: () => {
            setActionModal(prev => ({
              ...prev,
              [ACTION_MODALS.LICENSE]: true
            }));
          }
        },
        {
          name: "Delete",
          disabled: !isRowSelected,
          action: () => {
            setActionModal(prev => ({
              ...prev,
              [ACTION_MODALS.DELETE]: true
            }));
          }
        },
        {
          name: "Read Mail",
          action: () => {
            getEmails(params);
          }
        }
      ]
    },
    "separator",
    {
      name: "Export",
      icon: '<i class="fa fa-download"/>',
      subMenu: [
        {
          name: "All",
          action: () => {
            params.api.exportDataAsCsv({
              allColumns: true,
              processHeaderCallback,
              suppressQuotes: true,
              skipColumnGroupHeaders: true
            });
          }
        },
        {
          name: "Visible Only",
          action: () => {
            params.api.exportDataAsCsv({
              processHeaderCallback,
              suppressQuotes: true,
              skipColumnGroupHeaders: true
            });
          }
        },

        {
          name: "Selected All",
          action: () => {
            params.api.exportDataAsCsv({
              processHeaderCallback,
              suppressQuotes: true,
              onlySelected: true,
              allColumns: true,
              skipColumnGroupHeaders: true
            });
          }
        },

        {
          name: "Selected Visible",
          action: () => {
            params.api.exportDataAsCsv({
              processHeaderCallback,
              suppressQuotes: true,
              onlySelected: true,
              skipColumnGroupHeaders: true
            });
          }
        }
      ]
    },
    {
      name: "Import",
      icon: '<i class="fa fa-upload"/>',
      subMenu: [
        {
          name: "Upload CSV File",
          action: () => {
            setActionModal(prev => ({
              ...prev,
              [ACTION_MODALS.UPLOAD_CSV]: true
            }));
          }
        },
        {
          name: "Download Import example",
          action: () => {
            getCsvSample();
          }
        }
      ]
    },
    {
      name: `Views${active ? " (" + active.name + ")" : ""}`,
      icon: '<i class="fa fa-table" />',
      subMenu: [
        {
          name: "Save View",
          icon: '<i class="fa fa-save" />',
          action: () => toggle()
        },
        ...filterList.map((el, index) =>
          el.isRemoveable
            ? {
                name: el.name,
                action: () => restoreState(el, index),
                subMenu: [
                  { name: "Open", action: () => restoreState(el, index) },
                  {
                    name: "Remove",
                    icon: '<i class="fa fa-close" />',
                    action: () => removeFilter(el, index)
                  }
                ]
              }
            : { name: el.name, action: () => restoreState(el, index) }
        )
      ]
    },
    {
      name: "Expand All",
      action: () => params.api.expandAll()
    },
    {
      name: "Collapse All",
      action: () => params.api.collapseAll()
    }
  ];

  return result;
};

const processHeaderCallback = e => {
  const { column } = e;
  const headerName = column?.colDef?.headerName;
  const parentName = column?.originalParent?.colGroupDef?.headerName;
  return parentName ? `${parentName}_${headerName}` : headerName;
};
