import moment from "moment";
import { numberComparator } from "../../utils";
// import { psnRegCountryRenderer } from "./tableRenderers";

export const adminColumnDefs = [
  {
    headerName: "Farm",
    children: [
      {
        headerName: "Farm Name",
        field: "customer_farm_name",
        sortable: true,
        width: 90,
        enableRowGroup: true,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true,
        columnGroupShow: undefined
      },
      {
        headerName: "Server",
        field: "server",
        sortable: true,
        width: 90,
        enableRowGroup: true,
        columnGroupShow: "closed"
      },
      {
        headerName: "Alias",
        field: "alias",
        width: 90,
        sortable: true,
        enableRowGroup: true,
        columnGroupShow: "closed"
      }
    ]
  },
  {
    headerName: "PSN",
    children: [
      {
        headerName: "Email",
        field: "email",
        sortable: true,
        width: 90,
        enableRowGroup: true,
        columnGroupShow: null
      },
      {
        headerName: "Password",
        field: "password",
        width: 90,
        sortable: true,
        enableRowGroup: true,
        columnGroupShow: "closed"
      },
      {
        headerName: "PSN Gauth",
        field: "psn_gauth",
        width: 90,
        sortable: true,
        enableRowGroup: true,
        columnGroupShow: "closed"
      },

      {
        headerName: "Backups",
        field: "psn_backups",
        width: 90,
        sortable: true,
        enableRowGroup: true,
        columnGroupShow: "closed"
      }
    ]
  },
  {
    headerName: "Steam",
    children: [
      {
        headerName: "Account",
        field: "steam_accountname",
        width: 90,
        sortable: true,
        enableRowGroup: true,
        columnGroupShow: "closed"
      },
      {
        headerName: "Keys",
        field: "steam_keys",
        width: 90,
        sortable: true,
        enableRowGroup: true,
        columnGroupShow: "closed"
      }
    ]
  },
  {
    headerName: "PC",
    children: [
      {
        headerName: "hwid",
        field: "hwid",
        sortable: true,
        width: 90,
        enableRowGroup: true,
        columnGroupShow: null
      }
    ]
  },
  {
    headerName: "PSN Reg",
    children: [
      {
        headerName: "Reg status",
        field: "reg_status",
        sortable: true,
        width: 90,
        enableRowGroup: true,
        columnGroupShow: null
      },
      {
        headerName: "Birthday",
        field: "dob",
        width: 90,
        sortable: true,
        enableRowGroup: true,
        columnGroupShow: "closed"
      },
      {
        headerName: "First Name",
        field: "firstname",
        sortable: true,
        width: 90,
        enableRowGroup: true,
        columnGroupShow: "closed"
      },
      {
        headerName: "Last Name",
        field: "lastname",
        width: 90,
        sortable: true,
        enableRowGroup: true,
        columnGroupShow: "closed"
      },
      {
        headerName: "Country",
        field: "psn_reg_country",
        width: 90,
        sortable: true,
        enableRowGroup: true,
        columnGroupShow: "closed"
        // cellRenderer: psnRegCountryRenderer
      }
    ]
  },
  {
    headerName: "EA",
    children: [
      {
        headerName: "Email",
        field: "ea_email",
        width: 90,
        sortable: true,
        enableRowGroup: true,
        columnGroupShow: null
      },
      {
        headerName: "Password",
        field: "ea_password",
        width: 90,
        sortable: true,
        enableRowGroup: true,
        columnGroupShow: "closed"
      },
      {
        headerName: "Gauth",
        field: "gauth",
        width: 90,
        sortable: true,
        enableRowGroup: true,
        columnGroupShow: "closed"
      },
      {
        headerName: "Proxy",
        field: "ea_proxy",
        width: 90,
        sortable: true,
        enableRowGroup: true,
        columnGroupShow: "closed"
      },
      {
        headerName: "Backups",
        field: "ea_backups",
        width: 90,
        sortable: true,
        enableRowGroup: true,
        columnGroupShow: "closed"
      }
    ]
  },
  {
    headerName: "EA Reg",
    children: [
      {
        headerName: "Origin ID",
        field: "originId",
        width: 90,
        sortable: true,
        enableRowGroup: true,
        columnGroupShow: null
      },
      {
        headerName: "Security Answer",
        field: "ea_securityAnswer",
        sortable: true,
        width: 90,
        enableRowGroup: true,
        columnGroupShow: "closed"
      },
      {
        headerName: "Country",
        field: "country",
        sortable: true,
        width: 90,
        enableRowGroup: true,
        columnGroupShow: "closed"
      },
      {
        headerName: "Birthday",
        field: "ea_birthday",
        sortable: true,
        width: 90,
        enableRowGroup: true,
        columnGroupShow: "closed"
      },
      {
        headerName: "First Name",
        field: "ea_firstname",
        sortable: true,
        width: 90,
        enableRowGroup: true,
        columnGroupShow: "closed"
      },
      {
        headerName: "Last Name",
        field: "ea_lastname",
        sortable: true,
        width: 90,
        enableRowGroup: true,
        columnGroupShow: "closed"
      },
      {
        headerName: "IMAP User",
        field: "ea_imapusername",
        sortable: true,
        width: 90,
        enableRowGroup: true,
        columnGroupShow: "closed"
      },
      {
        headerName: "IMAP Password",
        field: "ea_imappassword",
        sortable: true,
        width: 90,
        enableRowGroup: true,
        columnGroupShow: "closed"
      },
      {
        headerName: "IMAP Host",
        field: "ea_imaphost",
        sortable: true,
        width: 90,
        enableRowGroup: true,
        columnGroupShow: "closed"
      },
      {
        headerName: "IMAP Port",
        field: "ea_imapport",
        sortable: true,
        width: 90,
        enableRowGroup: true,
        columnGroupShow: "closed"
      },
      {
        headerName: "Reg status",
        field: "ea_reg_status",
        sortable: true,
        width: 90,
        enableRowGroup: true,
        columnGroupShow: "closed"
      },
      {
        headerName: "Reg Date",
        field: "ea_reg_date",
        sortable: true,
        width: 90,
        enableRowGroup: true,
        columnGroupShow: "closed"
      }
    ]
  },
  {
    headerName: "Script",
    children: [
      {
        headerName: "Status",
        field: "status",
        width: 90,
        sortable: true,
        editable: true,
        enableRowGroup: true,
        cellClassRules: {
          "rag-green": params => {
            return params.data && params.data.status
              ? params.data.status.toLowerCase() === "working"
              : false;
          },
          "rag-yellow": params => {
            return params.data && params.data.status
              ? params.data.status.toLowerCase() === "waiting"
              : false;
          },
          "rag-red": params => {
            return params.data && params.data.status
              ? params.data.status.toLowerCase() === "error"
              : false;
          },
          "rag-dark": params => {
            return params.data && params.data.status
              ? params.data.status.toLowerCase() === "inactive"
              : false;
          }
        },
        columnGroupShow: null
      },
      {
        headerName: "Status Message",
        field: "status_message",
        width: 90,
        sortable: true,
        enableRowGroup: true,
        columnGroupShow: "closed"
      },
      {
        headerName: "Description",
        field: "description",
        width: 90,
        sortable: true,
        enableRowGroup: true,
        columnGroupShow: "closed"
      },
      {
        headerName: "FIFA stats",
        field: "fifa_stats",
        width: 90,
        sortable: true,
        enableRowGroup: true,
        columnGroupShow: "closed"
      },
      {
        headerName: "Coins",
        field: "coins",
        comparator: numberComparator,
        width: 90,
        sortable: true,
        enableRowGroup: true,
        filter: "agNumberColumnFilter",
        columnGroupShow: "closed"
      },
      {
        headerName: "SB points",
        field: "sb_points",
        width: 90,
        sortable: true,
        enableRowGroup: true,
        columnGroupShow: "closed"
      },
      {
        headerName: "Muled (hrs ago)",
        field: "muled",
        width: 90,
        sortable: true,
        enableRowGroup: true,
        editable: false,
        valueFormatter: params => {
          if (params.value) {
            const itemTime = moment.utc(params.value).add(1, "minutes");
            const curentTime = moment.utc(new Date());
            const diff = curentTime.diff(itemTime, "hours");
            return diff;
          } else {
            return params.value;
          }
        },
        columnGroupShow: "closed"
      },

      {
        headerName: "Last Update (min ago)",
        field: "times_tamp",
        width: 200,
        sortable: true,
        enableRowGroup: true,
        editable: false,
        valueFormatter: params => {
          if (params.value) {
            const itemTime = moment.utc(params.value).add(1, "minutes");
            const curentTime = moment.utc(new Date());
            const diff = curentTime.diff(itemTime, "minutes");

            return diff > 1000 ? 999 : diff;
          } else {
            return 999;
          }
        },
        columnGroupShow: "closed"
      },
      {
        headerName: "Script",
        field: "script",
        width: 90,
        sortable: true,
        enableRowGroup: true,
        columnGroupShow: "closed"
      },
      {
        headerName: "TM unlocked",
        field: "tm_unlocked",
        width: 90,
        sortable: true,
        enableRowGroup: true,
        columnGroupShow: "closed"
      },
      {
        headerName: "Args",
        field: "args",
        width: 90,
        sortable: true,
        enableRowGroup: true,
        columnGroupShow: "closed"
      },
      {
        headerName: "Config",
        field: "config",
        width: 90,
        sortable: true,
        enableRowGroup: true,
        columnGroupShow: "closed"
      },
      {
        headerName: "Screen Shot",
        field: "screen_shot_link",
        width: 90,
        sortable: true,
        enableRowGroup: true,
        columnGroupShow: "closed"
      }
    ]
  },
  {
    headerName: "Proxy",
    children: [
      {
        headerName: "IP",
        field: "proxyip",
        width: 90,
        sortable: true,
        enableRowGroup: true,
        columnGroupShow: null
      },
      {
        headerName: "Port",
        field: "proxyport",
        width: 90,
        sortable: true,
        enableRowGroup: true,
        columnGroupShow: "closed"
      },
      {
        headerName: "Login",
        field: "proxy_login",
        width: 90,
        sortable: true,
        enableRowGroup: true,
        columnGroupShow: "closed"
      },
      {
        headerName: "Password",
        field: "proxy_password",
        width: 90,
        sortable: true,
        enableRowGroup: true,
        columnGroupShow: "closed"
      },
      {
        headerName: "Rotation Link",
        field: "rotation_link",
        width: 90,
        sortable: true,
        enableRowGroup: true,
        columnGroupShow: "closed"
      }
    ]
  },
  {
    headerName: "Pairing",
    children: [
      {
        headerName: "PSN Token",
        field: "psn_token",
        width: 90,
        sortable: true,
        enableRowGroup: true,
        columnGroupShow: null
      },
      {
        headerName: "Pairing Status",
        field: "pairing_status",
        width: 90,
        sortable: true,
        enableRowGroup: true,
        columnGroupShow: "closed"
      },
      {
        headerName: "PSN Signin Status",
        field: "psn_signin_status",
        width: 90,
        sortable: true,
        enableRowGroup: true,
        columnGroupShow: "closed"
      },

      {
        headerName: "PSNID",
        field: "psnid",
        width: 90,
        sortable: true,
        enableRowGroup: true,
        columnGroupShow: "closed"
      },
      {
        headerName: "RP Regist key",
        field: "rp_regist_key",
        width: 90,
        sortable: true,
        enableRowGroup: true,
        columnGroupShow: "closed"
      },
      {
        headerName: "RP key",
        field: "rp_key",
        width: 90,
        sortable: true,
        enableRowGroup: true,
        columnGroupShow: "closed"
      }
    ]
  },
  {
    headerName: "IMAP",
    children: [
      {
        headerName: "IMAP Login",
        field: "imap_login",
        width: 90,
        sortable: true,
        enableRowGroup: true,
        columnGroupShow: null
      },
      {
        headerName: "IMAP Password",
        field: "imap_password",
        width: 90,
        sortable: true,
        enableRowGroup: true,
        columnGroupShow: "closed"
      },
      {
        headerName: "Web Password",
        field: "email_web_password",
        width: 90,
        sortable: true,
        enableRowGroup: true,
        columnGroupShow: "closed"
      },
      {
        headerName: "IMAP Host",
        field: "imap_host",
        width: 90,
        sortable: true,
        enableRowGroup: true,
        columnGroupShow: "closed"
      },
      {
        headerName: "IMAP Proxy",
        field: "imap_proxy",
        width: 90,
        sortable: true,
        enableRowGroup: true,
        columnGroupShow: "closed"
      },
      {
        headerName: "IMAP Gauth",
        field: "imap_gauth",
        width: 90,
        sortable: true,
        enableRowGroup: true,
        columnGroupShow: "closed"
      }
    ]
  },
  {
    headerName: "Bans",
    children: [
      {
        headerName: "Reg ban",
        field: "reg_ban",
        width: 90,
        sortable: true,
        enableRowGroup: true,
        columnGroupShow: null
      },
      {
        headerName: "Muling ban",
        field: "muling_ban",
        width: 90,
        sortable: true,
        enableRowGroup: true,
        columnGroupShow: "closed"
      },
      {
        headerName: "Botting ban",
        field: "botting_ban",
        width: 90,
        sortable: true,
        enableRowGroup: true,
        columnGroupShow: "closed"
      },
      {
        headerName: "Ban check status",
        field: "ban_check_status",
        width: 180,
        sortable: true,
        enableRowGroup: true,
        columnGroupShow: "closed"
      }
    ]
  },
  {
    headerName: "Console",
    children: [
      {
        headerName: "Type",
        field: "type",
        width: 70,
        sortable: true,
        enableRowGroup: true,
        columnGroupShow: null
      },
      {
        headerName: "Avatar",
        field: "avatar",
        width: 40,
        sortable: true,
        enableRowGroup: true,
        columnGroupShow: "closed"
      },
      {
        headerName: "IP address",
        field: "console_ip",
        width: 90,
        sortable: true,
        enableRowGroup: true,
        editable: true,
        columnGroupShow: "closed"
      },
      {
        headerName: "Public IP",
        field: "public_ip",
        width: 90,
        sortable: true,
        enableRowGroup: true,
        editable: true,
        columnGroupShow: "closed"
      },
      {
        headerName: "IP last update",
        field: "console_ip_actual_time",
        width: 90,
        sortable: true,
        editable: false,
        enableRowGroup: true,
        valueFormatter: params => {
          if (!params.value) return "--";
          else return new Date(parseInt(params.value)).toLocaleString();
        },
        columnGroupShow: "closed"
      },
      {
        headerName: "Storage",
        field: "storage",
        width: 90,
        sortable: true,
        enableRowGroup: true,
        editable: true,
        columnGroupShow: "closed"
      },
      {
        headerName: "MAC",
        editable: true,
        field: "mac_address",
        width: 90,
        sortable: true,
        enableRowGroup: true,
        cellClassRules: {
          "rag-green": params => {
            return params.data && params.data.status
              ? params.data.status.toLowerCase() === "working"
              : false;
          }
        },
        columnGroupShow: "closed"
      },
      {
        headerName: "License Exp",
        field: "license_expiration",
        width: 90,
        sortable: true,
        enableRowGroup: true,
        editable: false,
        valueFormatter: params => {
          if (params.value) {
            const itemTime = moment(params.value);
            const curentTime = moment(new Date());
            const diff = itemTime.diff(curentTime);
            const d = moment.duration(diff);
            const days = parseInt(d.asDays()); //84
            let hours = parseInt(d.asHours()); //2039 hours, but it gives total hours in given miliseconds which is not expacted.
            hours = hours - days * 24; // 23 hours

            return `${days}d ${hours}h`; // ${minutes}m
          }
          return "";
        },
        columnGroupShow: "closed"
      }
    ]
  }
];

export const userColumnDefs = [
  {
    headerName: "Farm",
    children: [
      {
        headerName: "Farm Name",
        field: "customer_farm_name",
        sortable: true,
        width: 90,
        enableRowGroup: true,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true,
        columnGroupShow: undefined
      },
      {
        headerName: "Server",
        field: "server",
        sortable: true,
        width: 90,
        enableRowGroup: true,
        columnGroupShow: "closed"
      },
      {
        headerName: "Alias",
        field: "alias",
        width: 90,
        sortable: true,
        enableRowGroup: true,
        columnGroupShow: "closed"
      }
    ]
  },
  {
    headerName: "PSN",
    children: [
      {
        headerName: "Email",
        field: "email",
        sortable: true,
        width: 90,
        enableRowGroup: true,
        columnGroupShow: undefined
      },
      {
        headerName: "Password",
        field: "password",
        width: 90,
        sortable: true,
        enableRowGroup: true,
        columnGroupShow: "closed"
      },
      {
        headerName: "PSN Gauth",
        field: "psn_gauth",
        width: 90,
        sortable: true,
        enableRowGroup: true,
        columnGroupShow: "closed"
      },
      {
        headerName: "Backups",
        field: "psn_backups",
        width: 90,
        sortable: true,
        enableRowGroup: true,
        columnGroupShow: "closed"
      }
    ]
  },
  {
    headerName: "Steam",
    children: [
      {
        headerName: "Account",
        field: "steam_accountname",
        width: 90,
        sortable: true,
        enableRowGroup: true,
        columnGroupShow: "closed"
      },
      {
        headerName: "Keys",
        field: "steam_keys",
        width: 90,
        sortable: true,
        enableRowGroup: true,
        columnGroupShow: "closed"
      }
    ]
  },
  {
    headerName: "PC",
    children: [
      {
        headerName: "hwid",
        field: "hwid",
        sortable: true,
        width: 90,
        enableRowGroup: true,
        columnGroupShow: null
      }
    ]
  },
  {
    headerName: "PSN Reg",
    children: [
      {
        headerName: "Reg status",
        field: "reg_status",
        sortable: true,
        width: 90,
        enableRowGroup: true,
        columnGroupShow: undefined
      },
      {
        headerName: "Birthday",
        field: "dob",
        width: 90,
        sortable: true,
        enableRowGroup: true,
        columnGroupShow: "closed"
      },
      {
        headerName: "First Name",
        field: "firstname",
        sortable: true,
        width: 90,
        enableRowGroup: true,
        columnGroupShow: "closed"
      },
      {
        headerName: "Last Name",
        field: "lastname",
        width: 90,
        sortable: true,
        enableRowGroup: true,
        columnGroupShow: "closed"
      },
      {
        headerName: "Country",
        field: "psn_reg_country",
        width: 90,
        sortable: true,
        enableRowGroup: true,
        columnGroupShow: "closed"
        // cellRenderer: psnRegCountryRenderer
      }
    ]
  },
  {
    headerName: "EA",
    children: [
      {
        headerName: "Email",
        field: "ea_email",
        width: 90,
        sortable: true,
        enableRowGroup: true,
        columnGroupShow: undefined
      },
      {
        headerName: "Password",
        field: "ea_password",
        width: 90,
        sortable: true,
        enableRowGroup: true,
        columnGroupShow: "closed"
      },
      {
        headerName: "Gauth",
        field: "gauth",
        width: 90,
        sortable: true,
        enableRowGroup: true,
        columnGroupShow: "closed"
      },
      {
        headerName: "Proxy",
        field: "ea_proxy",
        width: 90,
        sortable: true,
        enableRowGroup: true,
        columnGroupShow: "closed"
      },
      {
        headerName: "Backups",
        field: "ea_backups",
        width: 90,
        sortable: true,
        enableRowGroup: true,
        columnGroupShow: "closed"
      }
    ]
  },
  {
    headerName: "EA Reg",
    children: [
      {
        headerName: "Origin ID",
        field: "originId",
        width: 90,
        sortable: true,
        enableRowGroup: true,
        columnGroupShow: undefined
      },
      {
        headerName: "Security Answer",
        field: "ea_securityAnswer",
        sortable: true,
        width: 90,
        enableRowGroup: true,
        columnGroupShow: "closed"
      },
      {
        headerName: "Country",
        field: "country",
        sortable: true,
        width: 90,
        enableRowGroup: true,
        columnGroupShow: "closed"
      },
      {
        headerName: "Birthday",
        field: "ea_birthday",
        sortable: true,
        width: 90,
        enableRowGroup: true,
        columnGroupShow: "closed"
      },
      {
        headerName: "First Name",
        field: "ea_firstname",
        sortable: true,
        width: 90,
        enableRowGroup: true,
        columnGroupShow: "closed"
      },
      {
        headerName: "Last Name",
        field: "ea_lastname",
        sortable: true,
        width: 90,
        enableRowGroup: true,
        columnGroupShow: "closed"
      },
      {
        headerName: "IMAP User",
        field: "ea_imapusername",
        sortable: true,
        width: 90,
        enableRowGroup: true,
        columnGroupShow: "closed"
      },
      {
        headerName: "IMAP Password",
        field: "ea_imappassword",
        sortable: true,
        width: 90,
        enableRowGroup: true,
        columnGroupShow: "closed"
      },
      {
        headerName: "IMAP Host",
        field: "ea_imaphost",
        sortable: true,
        width: 90,
        enableRowGroup: true,
        columnGroupShow: "closed"
      },
      {
        headerName: "IMAP Port",
        field: "ea_imapport",
        sortable: true,
        width: 90,
        enableRowGroup: true,
        columnGroupShow: "closed"
      },
      {
        headerName: "Reg status",
        field: "ea_reg_status",
        sortable: true,
        width: 90,
        enableRowGroup: true,
        columnGroupShow: "closed"
      },
      {
        headerName: "Reg Date",
        field: "ea_reg_date",
        sortable: true,
        width: 90,
        enableRowGroup: true,
        columnGroupShow: "closed"
      }
    ]
  },
  {
    headerName: "Script",
    children: [
      {
        headerName: "Status",
        field: "status",
        width: 90,
        sortable: true,
        editable: true,
        enableRowGroup: true,
        cellClassRules: {
          "rag-green": params => {
            return params.data && params.data.status
              ? params.data.status.toLowerCase() === "working"
              : false;
          },
          "rag-yellow": params => {
            return params.data && params.data.status
              ? params.data.status.toLowerCase() === "waiting"
              : false;
          },
          "rag-red": params => {
            return params.data && params.data.status
              ? params.data.status.toLowerCase() === "error"
              : false;
          },
          "rag-dark": params => {
            return params.data && params.data.status
              ? params.data.status.toLowerCase() === "inactive"
              : false;
          }
        },
        columnGroupShow: undefined
      },
      {
        headerName: "Status Message",
        field: "status_message",
        width: 90,
        sortable: true,
        enableRowGroup: true,
        columnGroupShow: "closed"
      },
      {
        headerName: "Description",
        field: "description",
        width: 90,
        sortable: true,
        enableRowGroup: true,
        columnGroupShow: "closed"
      },
      {
        headerName: "FIFA stats",
        field: "fifa_stats",
        width: 90,
        sortable: true,
        enableRowGroup: true,
        columnGroupShow: "closed"
      },

      {
        headerName: "Coins",
        field: "coins",
        comparator: numberComparator,
        width: 90,
        sortable: true,
        enableRowGroup: true,
        filter: "agNumberColumnFilter",
        columnGroupShow: "closed"
      },
      {
        headerName: "SB points",
        field: "sb_points",
        width: 90,
        sortable: true,
        enableRowGroup: true,
        columnGroupShow: "closed"
      },
      {
        headerName: "Muled (hrs ago)",
        field: "muled",
        width: 90,
        sortable: true,
        enableRowGroup: true,
        editable: false,
        valueFormatter: params => {
          if (params.value) {
            const itemTime = moment.utc(params.value).add(1, "minutes");
            const curentTime = moment.utc(new Date());
            const diff = curentTime.diff(itemTime, "hours");
            return diff;
          } else {
            return params.value;
          }
        },
        columnGroupShow: "closed"
      },

      {
        headerName: "Last Update (min ago)",
        field: "times_tamp",
        width: 200,
        sortable: true,
        enableRowGroup: true,
        editable: false,
        valueFormatter: params => {
          if (params.value) {
            const itemTime = moment.utc(params.value).add(1, "minutes");
            const curentTime = moment.utc(new Date());
            const diff = curentTime.diff(itemTime, "minutes");
            return diff > 1000 ? 999 : diff;
          } else {
            return 999;
          }
        },
        columnGroupShow: "closed"
      },
      {
        headerName: "Script",
        field: "script",
        width: 90,
        sortable: true,
        enableRowGroup: true,
        columnGroupShow: "closed"
      },
      {
        headerName: "TM unlocked",
        field: "tm_unlocked",
        width: 90,
        sortable: true,
        enableRowGroup: true,
        columnGroupShow: "closed"
      },
      {
        headerName: "Args",
        field: "args",
        width: 90,
        sortable: true,
        enableRowGroup: true,
        columnGroupShow: "closed"
      },
      {
        headerName: "Config",
        field: "config",
        width: 90,
        sortable: true,
        enableRowGroup: true,
        columnGroupShow: "closed"
      },
      {
        headerName: "Screen Shot",
        field: "screen_shot_link",
        width: 90,
        sortable: true,
        enableRowGroup: true,
        columnGroupShow: "closed"
      }
    ]
  },
  {
    headerName: "Proxy",
    children: [
      {
        headerName: "IP",
        field: "proxyip",
        width: 90,
        sortable: true,
        enableRowGroup: true,
        columnGroupShow: undefined
      },
      {
        headerName: "Port",
        field: "proxyport",
        width: 90,
        sortable: true,
        enableRowGroup: true,
        columnGroupShow: "closed"
      },
      {
        headerName: "Login",
        field: "proxy_login",
        width: 90,
        sortable: true,
        enableRowGroup: true,
        columnGroupShow: "closed"
      },
      {
        headerName: "Password",
        field: "proxy_password",
        width: 90,
        sortable: true,
        enableRowGroup: true,
        columnGroupShow: "closed"
      },
      {
        headerName: "Rotation Link",
        field: "rotation_link",
        width: 90,
        sortable: true,
        enableRowGroup: true,
        columnGroupShow: "closed"
      }
    ]
  },
  {
    headerName: "Pairing",
    children: [
      {
        headerName: "PSN Token",
        field: "psn_token",
        width: 90,
        sortable: true,
        enableRowGroup: true,
        columnGroupShow: undefined
      },
      {
        headerName: "Pairing Status",
        field: "pairing_status",
        width: 90,
        sortable: true,
        enableRowGroup: true,
        columnGroupShow: "closed"
      },
      {
        headerName: "PSN Signin Status",
        field: "psn_signin_status",
        width: 90,
        sortable: true,
        enableRowGroup: true,
        columnGroupShow: "closed"
      },

      {
        headerName: "PSNID",
        field: "psnid",
        width: 90,
        sortable: true,
        enableRowGroup: true,
        columnGroupShow: "closed"
      }
    ]
  },
  {
    headerName: "IMAP",
    children: [
      {
        headerName: "IMAP Login",
        field: "imap_login",
        width: 90,
        sortable: true,
        enableRowGroup: true,
        columnGroupShow: undefined
      },
      {
        headerName: "IMAP Password",
        field: "imap_password",
        width: 90,
        sortable: true,
        enableRowGroup: true,
        columnGroupShow: "closed"
      },
      {
        headerName: "Web Password",
        field: "email_web_password",
        width: 90,
        sortable: true,
        enableRowGroup: true,
        columnGroupShow: "closed"
      },
      {
        headerName: "IMAP Host",
        field: "imap_host",
        width: 90,
        sortable: true,
        enableRowGroup: true,
        columnGroupShow: "closed"
      },
      {
        headerName: "IMAP Proxy",
        field: "imap_proxy",
        width: 90,
        sortable: true,
        enableRowGroup: true,
        columnGroupShow: "closed"
      },
      {
        headerName: "IMAP Gauth",
        field: "imap_gauth",
        width: 90,
        sortable: true,
        enableRowGroup: true,
        columnGroupShow: "closed"
      }
    ]
  },
  {
    headerName: "Bans",
    children: [
      {
        headerName: "Reg ban",
        field: "reg_ban",
        width: 90,
        sortable: true,
        enableRowGroup: true,
        columnGroupShow: undefined
      },
      {
        headerName: "Muling ban",
        field: "muling_ban",
        width: 90,
        sortable: true,
        enableRowGroup: true,
        columnGroupShow: "closed"
      },
      {
        headerName: "Botting ban",
        field: "botting_ban",
        width: 90,
        sortable: true,
        enableRowGroup: true,
        columnGroupShow: "closed"
      },
      {
        headerName: "Ban check status",
        field: "ban_check_status",
        width: 180,
        sortable: true,
        enableRowGroup: true,
        columnGroupShow: "closed"
      }
    ]
  },
  {
    headerName: "Console",
    children: [
      {
        headerName: "Type",
        field: "type",
        width: 70,
        sortable: true,
        enableRowGroup: true,
        columnGroupShow: undefined
      },
      {
        headerName: "Avatar",
        field: "avatar",
        width: 40,
        sortable: true,
        enableRowGroup: true,
        columnGroupShow: "closed"
      },
      {
        headerName: "IP address",
        field: "console_ip",
        width: 90,
        sortable: true,
        enableRowGroup: true,
        editable: true,
        columnGroupShow: "closed"
      },
      {
        headerName: "Public IP",
        field: "public_ip",
        width: 90,
        sortable: true,
        enableRowGroup: true,
        editable: true,
        columnGroupShow: "closed"
      },
      {
        headerName: "IP last update",
        field: "console_ip_actual_time",
        width: 90,
        sortable: true,
        editable: false,
        enableRowGroup: true,
        valueFormatter: params => {
          if (!params.value) return "--";
          else return new Date(parseInt(params.value)).toLocaleString();
        },
        columnGroupShow: "closed"
      },
      {
        headerName: "Storage",
        field: "storage",
        width: 90,
        sortable: true,
        enableRowGroup: true,
        editable: true,
        columnGroupShow: "closed"
      },
      {
        headerName: "MAC",
        editable: true,
        field: "mac_address",
        width: 90,
        sortable: true,
        enableRowGroup: true,
        cellClassRules: {
          "rag-green": params => {
            return params.data && params.data.status
              ? params.data.status.toLowerCase() === "working"
              : false;
          }
        },
        columnGroupShow: "closed"
      },
      {
        headerName: "License Exp",
        field: "license_expiration",
        width: 90,
        sortable: true,
        enableRowGroup: true,
        editable: false,
        valueFormatter: params => {
          if (params.value) {
            const itemTime = moment(params.value);
            const curentTime = moment(new Date());
            const diff = itemTime.diff(curentTime);
            const d = moment.duration(diff);
            const days = parseInt(d.asDays()); //84
            let hours = parseInt(d.asHours()); //2039 hours, but it gives total hours in given miliseconds which is not expacted.
            hours = hours - days * 24; // 23 hours
            return `${days}d ${hours}h`; // ${minutes}m
          }
          return "";
        },
        columnGroupShow: "closed"
      }
    ]
  }
];

export const columnsForAutoResize = [
  "type",
  "avatar",
  "storage",
  "console_ip",
  "console_ip_actual_time",
  "public_ip",
  "psn_gauth",
  "steam_accountname",
  "steam_keys",
  "mac_address",
  "license_expiration",
  "times_tamp",
  "status_message",
  "description"
];
