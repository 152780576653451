import React from "react";
import {
  Row,
  Col,
  Button,
  Modal,
  ModalBody,
  Form,
  CustomInput,
  Label,
  Input
} from "reactstrap";
import { useFormik } from "formik";
import { useMutation } from "@apollo/react-hooks";
import {
  ADD_SCRIPT_SETTING,
  UPDATE_SCRIPT_SETTING
} from "../../../SettingsGraphQL";
import { isEmpty } from "lodash";
import { validationSchema } from "../../../validationSchema";

const getValubleValues = (selectedItem, initialValues) => {
  const values = {};
  Object.keys(selectedItem).forEach(key => {
    values[key] =
      selectedItem[key] === null ? initialValues[key] : selectedItem[key];
  });
  return values;
};

const initialValues = {
  name: "",
  ms_games_per_session: 3,
  ms_daily_match_limit: 7,
  ms_daily_interrupted_games_limit: 2,
  ms_daily_squad_battles_limit: 7,
  ms_daily_online_match_limit: 0,
  ms_session_timeout_factor: 2,
  ms_max_games_per_console_daily: 70,
  ms_stop_after_xcaptchas: 3,
  ms_sleep_x_hours_after_captcha: 3,
  ms_stop_console_after_x_captcha_fails: 3,
  ms_session_sleep_timeout: 0,
  allow_tm_unlocked_to_play: false,
  os_ntpserver: "pool.ntp.org",
  objectives: false,
  os_allow_digital_fifa_owners_to_play: false,
  log_in_to_webapp_daily: false,
  os_asian_mode: false,
  ai: false,
  os_work_space: "C:\\workspace",
  os_quit_if_servers_unreachable: false,
  server_issue_throttling: true,
  os_days_to_trade: "",
  instance_limit: 0,
  pack_preview: false,
  allow_auto_mulling: false,
  os_min_coins_to_mule: 30000,
  os_gpu_device_number: 0,
  os_video_decoding_codec: "DXVA2",
  total_accs: 0,
  assign_from_alias: "",
  force_public_ip_match: false,
  force_local_ip_match: false,
  discord_chat_id: "",
  ip_check_interval: 0,
  isp_name: "",
  local_ip: "",
  port: "",
  login: "",
  passw: ""
};

const ScriptForm = props => {
  const customerId = parseInt(localStorage.getItem("consolebot.userId"));
  const [addScript] = useMutation(ADD_SCRIPT_SETTING);
  const [updateScript] = useMutation(UPDATE_SCRIPT_SETTING);
  const {
    isOpen,
    closeFormModel,
    refetch,
    selectedItem,
    setSelectedItem,
    toastMessgage
  } = props;
  const formik = useFormik({
    initialValues: selectedItem
      ? getValubleValues(selectedItem, initialValues)
      : initialValues,
    enableReinitialize: true,
    validationSchema: validationSchema({}),
    onSubmit: async values => {
      const data = { ...values };
      data.customer_id = customerId;
      data.discord_chat_id = discord_chat_id || "0";
      data.port = port || null;
      try {
        if (selectedItem) {
          const variables = { id: parseInt(selectedItem.id), input: data };
          await updateScript({ variables });
          toastMessgage("success", "Update script setting!");
        } else {
          const variables = { input: data };
          await addScript({ variables });
          toastMessgage("success", "Added new script setting!");
        }
        refetch();
      } catch (e) {
        toastMessgage("error", e.message);
      }
      closeFormModel(false);
    }
  });

  const {
    handleChange,
    handleSubmit,
    setFieldValue,
    dirty,
    errors,
    resetForm
  } = formik;
  const handleOnChange = (evt, name) => {
    const { checked } = evt.target;
    setFieldValue(name, checked);
  };

  const handleChangeNumeric = e => {
    const {
      target: { value }
    } = e;
    if (!value || value.match(/^[0-9]+$/)) {
      handleChange(e);
    }
  };

  const handleChangeNumericWithNegative = e => {
    const {
      target: { value }
    } = e;
    if (!value || value.match(/^-?[0-9]+$/)) {
      handleChange(e);
    }
  };

  const {
    name,
    ms_games_per_session,
    ms_daily_match_limit,
    ms_daily_interrupted_games_limit,
    ms_daily_squad_battles_limit,
    ms_daily_online_match_limit,
    ms_session_timeout_factor,
    ms_max_games_per_console_daily,
    ms_stop_after_xcaptchas,
    ms_sleep_x_hours_after_captcha,
    ms_stop_console_after_x_captcha_fails,
    ms_session_sleep_timeout,
    allow_tm_unlocked_to_play,
    os_gpu_device_number,
    os_video_decoding_codec,
    force_public_ip_match,
    force_local_ip_match,
    os_ntpserver,
    objectives,
    discord_chat_id,
    ip_check_interval,
    os_allow_digital_fifa_owners_to_play,
    log_in_to_webapp_daily,
    os_asian_mode,
    ai,
    os_work_space,
    os_quit_if_servers_unreachable,
    server_issue_throttling,
    os_days_to_trade,
    instance_limit,
    pack_preview,
    allow_auto_mulling,
    os_min_coins_to_mule,
    isp_name,
    local_ip,
    port,
    login,
    passw,
    total_accs,
    assign_from_alias
  } = formik.values;

  const handleClickOnCancel = () => {
    closeFormModel(false);
    setSelectedItem({});
    resetForm();
  };

  const isValidField = fieldName => {
    if (!isEmpty(errors) && errors[fieldName]) {
      return true;
    }
    return false;
  };

  return (
    <div>
      <Modal isOpen={isOpen} className="config-modal-form">
        <Form onSubmit={handleSubmit}>
          <ModalBody className="number">
            {/* <div className="mb-2"> */}
            {/* <Row> */}
            {/* <Col sm={6}>
                  <div className="single-line-2">
                    <Label for="name" className="font-weight-bolder">
                      Name
                    </Label>
                    <Input
                      type="text"
                      name="name"
                      id="name"
                      placeholder="name"
                      onChange={handleChange}
                      value={name || ""}
                      invalid={isValidField("name")}
                    />
                  </div> */}
            {/* </Col> */}
            {/* </Row> */}
            {/* <hr className="border-light" /> */}
            {/* </div> */}
            <Row>
              <Col sm={6}>
                <div className="single-line-2 mb-4">
                  <Label for="name" className="font-weight-bolder">
                    Name
                  </Label>
                  <Input
                    type="text"
                    name="name"
                    id="name"
                    placeholder="name"
                    onChange={handleChange}
                    value={name || ""}
                    invalid={isValidField("name")}
                  />
                </div>
                <SettingsDivider name="Matching Settings" />
                <div className="mb-2 single-line-2">
                  <Label className="mb-1" for="ms_games_per_session">
                    Game per session
                  </Label>
                  <Input
                    type="number"
                    name="ms_games_per_session"
                    id="ms_games_per_session"
                    placeholder=""
                    onChange={handleChange}
                    value={ms_games_per_session}
                    invalid={isValidField("ms_games_per_session")}
                  />
                </div>
                <div className="mb-2 single-line-2">
                  <Label className="mb-1" for="ms_daily_match_limit">
                    Daily match limit
                  </Label>
                  <Input
                    type="text"
                    name="ms_daily_match_limit"
                    id="ms_daily_match_limit"
                    placeholder=""
                    onChange={handleChange}
                    value={ms_daily_match_limit}
                    invalid={isValidField("ms_daily_match_limit")}
                  />
                </div>
                <div className="mb-2 single-line-2">
                  <Label
                    className="mb-1"
                    for="ms_daily_interrupted_games_limit"
                  >
                    Daily interrupted games limit
                  </Label>
                  <Input
                    type="number"
                    name="ms_daily_interrupted_games_limit"
                    id="ms_daily_interrupted_games_limit"
                    placeholder=""
                    onChange={handleChange}
                    value={ms_daily_interrupted_games_limit}
                    invalid={isValidField("ms_daily_interrupted_games_limit")}
                  />
                </div>
                <div className="mb-2 single-line-2">
                  <Label className="mb-1" for="ms_daily_squad_battles_limit">
                    Daily squad battles limit
                  </Label>
                  <Input
                    type="number"
                    name="ms_daily_squad_battles_limit"
                    id="ms_daily_squad_battles_limit"
                    placeholder=""
                    onChange={handleChange}
                    value={ms_daily_squad_battles_limit}
                    invalid={isValidField("ms_daily_squad_battles_limit")}
                  />
                </div>
                <div className="mb-2 single-line-2">
                  <Label className="mb-1" for="ms_daily_online_match_limit">
                    Daily online match limit
                  </Label>
                  <Input
                    type="number"
                    name="ms_daily_online_match_limit"
                    id="ms_daily_online_match_limit"
                    placeholder=""
                    onChange={handleChange}
                    value={ms_daily_online_match_limit}
                    invalid={isValidField("ms_daily_online_match_limit")}
                  />
                </div>
                <div className="mb-2 single-line-2">
                  <Label className="mb-1" for="ms_session_timeout_factor">
                    Session timeout factor
                  </Label>
                  <Input
                    type="number"
                    name="ms_session_timeout_factor"
                    id="ms_session_timeout_factor"
                    placeholder=""
                    onChange={handleChange}
                    value={ms_session_timeout_factor}
                    invalid={isValidField("ms_session_timeout_factor")}
                  />
                </div>
                <div className="mb-2 single-line-2">
                  <Label className="mb-1" for="ms_max_games_per_console_daily">
                    Max games per console daily
                  </Label>
                  <Input
                    type="number"
                    name="ms_max_games_per_console_daily"
                    id="ms_max_games_per_console_daily"
                    placeholder=""
                    onChange={handleChange}
                    value={ms_max_games_per_console_daily}
                    invalid={isValidField("ms_max_games_per_console_daily")}
                  />
                </div>
                <div className="mb-2 single-line-2">
                  <Label className="mb-1" for="ms_stop_after_xcaptchas">
                    Stop after X captchas
                  </Label>
                  <Input
                    type="number"
                    name="ms_stop_after_xcaptchas"
                    id="ms_stop_after_xcaptchas"
                    placeholder=""
                    onChange={handleChange}
                    value={ms_stop_after_xcaptchas}
                    invalid={isValidField("ms_stop_after_xcaptchas")}
                  />
                </div>
                <div className="mb-2 single-line-2">
                  <Label className="mb-1" for="ms_sleep_x_hours_after_captcha">
                    Sleep X hours after captcha
                  </Label>
                  <Input
                    type="number"
                    name="ms_sleep_x_hours_after_captcha"
                    id="ms_sleep_x_hours_after_captcha"
                    placeholder=""
                    onChange={handleChange}
                    value={ms_sleep_x_hours_after_captcha}
                    invalid={isValidField("ms_sleep_x_hours_after_captcha")}
                  />
                </div>
                <div className="mb-2 single-line-2">
                  <Label
                    className="mb-1"
                    for="ms_stop_console_after_x_captcha_fails"
                  >
                    Stop console after X captcha fails
                  </Label>
                  <Input
                    type="number"
                    name="ms_stop_console_after_x_captcha_fails"
                    id="ms_stop_console_after_x_captcha_fails"
                    placeholder=""
                    onChange={handleChange}
                    value={ms_stop_console_after_x_captcha_fails}
                    invalid={isValidField(
                      "ms_stop_console_after_x_captcha_fails"
                    )}
                  />
                </div>
                <div className="mb-2 single-line-2">
                  <Label className="mb-1" for="ms_session_sleep_timeout">
                    Session sleep timeout
                  </Label>
                  <Input
                    type="number"
                    name="ms_session_sleep_timeout"
                    id="ms_session_sleep_timeout"
                    placeholder=""
                    onChange={handleChange}
                    value={ms_session_sleep_timeout}
                    invalid={isValidField("ms_session_sleep_timeout")}
                  />
                </div>

                <div className="my-3">
                  <CustomInput
                    type="switch"
                    className="custom-switch-right"
                    id="allow_tm_unlocked_to_play"
                    name="customSwitch"
                    label="Allow TM unlocked to play"
                    onChange={event =>
                      handleOnChange(event, "allow_tm_unlocked_to_play")
                    }
                    checked={allow_tm_unlocked_to_play}
                  />
                </div>
                <SettingsDivider name="Router Settings" />
                <div className="mb-2 single-line-2">
                  <Label className="mb-1" for="local_ip">
                    Local IP
                  </Label>
                  <Input
                    type="text"
                    name="local_ip"
                    id="local_ip"
                    placeholder=""
                    onChange={handleChange}
                    value={local_ip}
                    invalid={isValidField("local_ip")}
                  />
                </div>
                <div className="mb-2 single-line-2">
                  <Label className="mb-1" for="port">
                    Port
                  </Label>
                  <Input
                    type="number"
                    name="port"
                    id="port"
                    placeholder="Port number"
                    invalid={isValidField("port")}
                    onChange={handleChangeNumeric}
                    value={port}
                  />
                </div>
                <div className="mb-2 single-line-2">
                  <Label className="mb-1" for="login">
                    Login
                  </Label>
                  <Input
                    type="text"
                    name="login"
                    id="login"
                    placeholder=""
                    onChange={handleChange}
                    value={login}
                    invalid={isValidField("login")}
                  />
                </div>
                <div className="mb-2 single-line-2">
                  <Label className="mb-1" for="login">
                    Pass
                  </Label>
                  <Input
                    type="text"
                    name="passw"
                    id="passw"
                    placeholder=""
                    onChange={handleChange}
                    value={passw}
                    invalid={isValidField("passw")}
                  />
                </div>
                <div className="mb-2 single-line-2">
                  <Label className="mb-1" for="isp_name">
                    ISP Name
                  </Label>
                  <Input
                    type="text"
                    name="isp_name"
                    id="isp_name"
                    placeholder=""
                    onChange={handleChange}
                    value={isp_name}
                    invalid={isValidField("isp_name")}
                  />
                </div>
                <div className="mb-2 single-line-2">
                  <Label className="mb-1" for="ip_check_interval">
                    IP check interval (minutes)
                  </Label>
                  <Input
                    type="number"
                    name="ip_check_interval"
                    id="ip_check_interval"
                    placeholder="IP check interval (minutes)"
                    onChange={handleChangeNumericWithNegative}
                    invalid={isValidField("ip_check_interval")}
                    value={ip_check_interval}
                  />
                </div>
                <div className="mt-3">
                  <CustomInput
                    type="switch"
                    className="custom-switch-right"
                    id="ForcePublicIPMatch"
                    name="customSwitch"
                    label="Force public IP match"
                    onChange={event =>
                      handleOnChange(event, "force_public_ip_match")
                    }
                    checked={force_public_ip_match || false}
                  />
                </div>
                <div className="mt-3">
                  <CustomInput
                    type="switch"
                    className="custom-switch-right"
                    id="ForceLocalIPMatch"
                    name="customSwitch"
                    label="Force local IP match"
                    onChange={event =>
                      handleOnChange(event, "force_local_ip_match")
                    }
                    checked={force_local_ip_match || false}
                  />
                </div>
              </Col>
              <Col sm={6}>
                <SettingsDivider name="Acc_Assignment_Settings" />
                <div className="mb-2 single-line-2">
                  <Label className="mb-1" for="total_accs">
                    Total Accounts
                  </Label>
                  <Input
                    type="number"
                    name="total_accs"
                    id="total_accs"
                    placeholder=""
                    onChange={handleChangeNumeric}
                    value={total_accs}
                    invalid={isValidField("total_accs")}
                  />
                </div>

                <div className="mb-2 single-line-2">
                  <Label className="mb-1" for="assign_from_alias">
                    Assign from Alias
                  </Label>
                  <Input
                    type="text"
                    name="assign_from_alias"
                    id="assign_from_alias"
                    placeholder=""
                    onChange={handleChange}
                    value={assign_from_alias}
                  />
                </div>
                <SettingsDivider name="Other Settings" />
                <div className="my-3">
                  <CustomInput
                    type="switch"
                    className="custom-switch-right"
                    id="objectives"
                    name="customSwitch"
                    label="Objectives"
                    onChange={event => handleOnChange(event, "objectives")}
                    checked={objectives}
                  />
                </div>
                <div className="mb-2 single-line-2">
                  <Label className="mb-1" for="os_ntpserver">
                    NTP server
                  </Label>
                  <Input
                    type="text"
                    name="os_ntpserver"
                    id="os_ntpserver"
                    placeholder=""
                    onChange={handleChange}
                    value={os_ntpserver}
                  />
                </div>

                <div className="my-3">
                  <CustomInput
                    type="switch"
                    className="custom-switch-right"
                    id="AllowDigitalFifaOwnerToPlay"
                    name="customSwitch"
                    label="Allow digital fifa owner to play"
                    onChange={event =>
                      handleOnChange(
                        event,
                        "os_allow_digital_fifa_owners_to_play"
                      )
                    }
                    checked={os_allow_digital_fifa_owners_to_play}
                  />
                </div>
                <div className="my-3">
                  <CustomInput
                    type="switch"
                    className="custom-switch-right"
                    id="Log_in_to_webapp_daily"
                    name="customSwitch"
                    label="Log in to webapp daily"
                    onChange={event =>
                      handleOnChange(event, "log_in_to_webapp_daily")
                    }
                    checked={log_in_to_webapp_daily}
                  />
                </div>
                <div className="my-3">
                  <CustomInput
                    type="switch"
                    className="custom-switch-right"
                    id="AsianMode"
                    name="customSwitch"
                    label="Asian mode"
                    onChange={event => handleOnChange(event, "os_asian_mode")}
                    checked={os_asian_mode}
                  />
                </div>

                <div className="my-3">
                  <CustomInput
                    type="switch"
                    className="custom-switch-right"
                    id="ai"
                    name="customSwitch"
                    label="Enable AI"
                    onChange={event => handleOnChange(event, "ai")}
                    checked={ai}
                  />
                </div>
                <div className="mb-2 single-line-2">
                  <Label className="mb-1" for="os_work_space">
                    Work space
                  </Label>
                  <Input
                    type="text"
                    name="os_work_space"
                    id="os_work_space"
                    placeholder=""
                    onChange={handleChange}
                    value={os_work_space}
                    invalid={isValidField("os_work_space")}
                  />
                </div>
                <div className="my-3">
                  <CustomInput
                    type="switch"
                    className="custom-switch-right"
                    id="QuitIfServersUnreachable"
                    name="customSwitch"
                    label="Quit if servers unreachable"
                    onChange={event =>
                      handleOnChange(event, "os_quit_if_servers_unreachable")
                    }
                    checked={os_quit_if_servers_unreachable}
                  />
                </div>
                <div className="my-3">
                  <CustomInput
                    type="switch"
                    className="custom-switch-right"
                    id="server_issue_throttling"
                    name="customSwitch"
                    label="Server issue throttling"
                    onChange={event =>
                      handleOnChange(event, "server_issue_throttling")
                    }
                    checked={server_issue_throttling}
                  />
                </div>

                <div className="mb-2 single-line-2">
                  <Label className="mb-1" for="instance_limit">
                    Instance Limit
                  </Label>
                  <Input
                    type="number"
                    name="instance_limit"
                    id="instance_limit"
                    placeholder=""
                    onChange={handleChangeNumeric}
                    value={instance_limit}
                    invalid={isValidField("instance_limit")}
                  />
                </div>
                <div className="mb-2 single-line-2">
                  <Label className="mb-1" for="os_days_to_trade">
                    Days to trade
                  </Label>
                  <Input
                    type="text"
                    name="os_days_to_trade"
                    id="os_days_to_trade"
                    placeholder=""
                    onChange={handleChange}
                    value={os_days_to_trade}
                  />
                </div>
                <div className="my-3">
                  <CustomInput
                    type="switch"
                    className="custom-switch-right"
                    id="pack_preview"
                    name="customSwitch"
                    label="Pack preview"
                    onChange={event => handleOnChange(event, "pack_preview")}
                    checked={pack_preview}
                  />
                </div>
                <div className="my-3">
                  <CustomInput
                    type="switch"
                    className="custom-switch-right"
                    id="AllowAutoMulling"
                    name="customSwitch"
                    label="Allow auto mulling"
                    onChange={event =>
                      handleOnChange(event, "allow_auto_mulling")
                    }
                    checked={allow_auto_mulling}
                  />
                </div>
                <div className="mb-2 single-line-2">
                  <Label className="mb-1" for="os_min_coins_to_mule">
                    Min coins to mule
                  </Label>
                  <Input
                    type="text"
                    name="os_min_coins_to_mule"
                    id="os_min_coins_to_mule"
                    placeholder=""
                    onChange={handleChange}
                    value={os_min_coins_to_mule}
                    invalid={isValidField("os_min_coins_to_mule")}
                  />
                </div>
                <div className="mb-2 single-line-2">
                  <Label className="mb-1" for="os_gpu_device_number">
                    GPU Device number
                  </Label>
                  <Input
                    type="number"
                    name="os_gpu_device_number"
                    id="os_gpu_device_number"
                    placeholder=""
                    onChange={handleChange}
                    value={os_gpu_device_number}
                    invalid={isValidField("os_gpu_device_number")}
                  />
                </div>
                <div className="mb-2 single-line-2">
                  <Label className="mb-1" for="os_video_decoding_codec">
                    Video Decoding Codec
                  </Label>
                  <Input
                    type="select"
                    onChange={handleChange}
                    name="os_video_decoding_codec"
                    id="os_video_decoding_codec"
                    value={os_video_decoding_codec}
                    invalid={isValidField("os_video_decoding_codec")}
                  >
                    <option>CUDA</option>
                    <option>DX11</option>
                    <option>DXVA2</option>
                  </Input>
                </div>

                <div className="mb-2 single-line-2">
                  <Label className="mb-1" for="discord_chat_id">
                    Discord chat id
                  </Label>
                  <Input
                    type="text"
                    name="discord_chat_id"
                    id="discord_chat_id"
                    placeholder="Discord chat id"
                    onChange={handleChangeNumeric}
                    value={discord_chat_id || ""}
                  />
                </div>
                <div className="modal-bottom-control">
                  <Button
                    color="primary"
                    size="sm"
                    type="submit"
                    disabled={!dirty}
                  >
                    Save
                  </Button>
                  <Button
                    color="secondary"
                    size="sm"
                    onClick={() => handleClickOnCancel()}
                  >
                    Cancel
                  </Button>
                </div>
              </Col>
            </Row>
          </ModalBody>
        </Form>
      </Modal>
    </div>
  );
};

const SettingsDivider = ({ name }) => (
  <div className="setting-category">
    <h6 className="script-row-title">{name}</h6>
  </div>
);

export default ScriptForm;
