import React, { PureComponent } from "react";
import {
  Container,
  Row,
  Col,
  FormGroup,
  Input,
  Label,
  Spinner
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay, faPause, faStop } from "@fortawesome/free-solid-svg-icons";
import ConsoleList from "./component/ConsoleList";
import ConsoleStatus from "./component/StatusFilter/consoleStatus";
import FarmList from "./component/StatusFilter/farmList";
import { GETOVERVIEW } from "../Configuration/ConfigurationGraphQL";
import { filter } from "lodash";
import { ACTION } from "../Configuration/ConfigurationGraphQL";
import {
  ToastsContainer,
  ToastsStore,
  ToastsContainerPosition
} from "react-toasts";
import { client } from "../../reducers/config";

class Status extends PureComponent {
  state = {
    searchField: { status: undefined, customer_farm_name: undefined },
    customer_farm_name: undefined,
    status: undefined,
    recordList: [],
    // dataBackup: [],
    statusData: {},
    isSelectAll: false,
    isLoading: false,
    isFarmLoading: false,
    isAnyConsoleSelected: false,
    selectedConsole: [],
    statuses: {}
  };

  async componentDidMount() {
    this.setState({ isFarmLoading: true });
    this.refetchConsole();
  }

  getQuery = () => {
    const user = JSON.parse(localStorage.getItem("consolebot.user"));
    if (user && user.is_super_admin) {
      return GETOVERVIEW;
    }
    return GETOVERVIEW;
  };

  refetchConsole = async () => {
    this.setState({ isLoading: true });
    const resConsole = await client.query({
      query: this.getQuery()
    });
    const recordList =
      resConsole && resConsole.data.getOverview
        ? this.transformData(resConsole.data.getOverview)
        : [];
    this.setState({
      recordList,
      // dataBackup: [...recordList],
      isLoading: false
    });
  };

  setStatusValue = async val => {
    await this.setState({ status: val });
  };

  setFarmValue = async val => {
    const customer_farm_name =
      val.customer_farm_name.length > 0 ? val.customer_farm_name : undefined;
    await this.setState({ customer_farm_name });
  };

  updateSeletectConsole = sldConsole => {
    this.setState({
      isAnyConsoleSelected: sldConsole.length ? true : false,
      selectedConsole: sldConsole
    });
  };

  transformData = consoleData => {
    if (consoleData) {
      const addCheckbox = list => {
        return list.map(item => {
          item.isChecked = false;
          return item;
        });
      };
      return addCheckbox(consoleData);
    }
  };

  onChangeSelectAll = event => {
    this.setState({
      isSelectAll: event.target.checked,
      isAnyConsoleSelected: event.target.checked
    });
  };

  consoleAction = async actType => {
    const { selectedConsole } = this.state;
    const selectedItems = filter(selectedConsole, "isChecked");
    try {
      const actionType = actType.toLowerCase();

      const consoleDetails = {
        ids: selectedItems.map(item => parseInt(item.id))
      };

      try {
        await client.mutate({
          mutation: ACTION,
          variables: { actionType, consoleDetails }
        });
        ToastsStore.success("Start request sent!");
      } catch (err) {
        ToastsStore.error(err.message);
      }
    } catch (e) {
      ToastsStore.error(e.message);
    }
  };

  setStatuses = statuses => {
    this.setState({ statuses });
  };

  changeDescriptionSearchString = descriptionSearchString => {
    this.setState({ descriptionSearchString });
  };

  render() {
    const {
      isSelectAll,
      status,
      descriptionSearchString,
      customer_farm_name,
      recordList,
      statuses,
      isAnyConsoleSelected,
      isLoading
    } = this.state;
    return (
      <main role="main" className="content-block__width ml-auto px-0">
        <div className="page-details-stripe">
          <Container fluid={true}>
            <Row className="justify-content-between align-items-center">
              <Col>
                <h1 className="stripe-heading m-0 font-weight-600">Overview</h1>
              </Col>
              <Col xs="auto">
                <div className="play-pause-stop">
                  <button
                    className="playBtn"
                    onClick={() => this.consoleAction("start")}
                    disabled={!isAnyConsoleSelected}
                  >
                    <FontAwesomeIcon icon={faPlay} size="xs" />
                  </button>
                  <button className="pauseBtn" disabled>
                    <FontAwesomeIcon icon={faPause} size="xs" />
                  </button>
                  <button
                    className="stopBtn"
                    onClick={() => this.consoleAction("stop")}
                    disabled={!isAnyConsoleSelected}
                  >
                    <FontAwesomeIcon icon={faStop} size="xs" />
                  </button>
                </div>
              </Col>
              <Col xs="auto" className="py-2">
                <div className="m-0 rounded border p-1">
                  <FormGroup check className="m-0">
                    <Label check className="font-size-12">
                      <Input
                        type="checkbox"
                        checked={isSelectAll}
                        onChange={this.onChangeSelectAll}
                        className="checkbox-alignment"
                      />
                      Select all
                    </Label>
                  </FormGroup>
                </div>
              </Col>
            </Row>
            {/* </Row> */}
          </Container>
        </div>
        <div className="content-wrapper">
          <Container fluid={true} className="status-borard-wrapper bg-white">
            <Row>
              <Col xs={"auto"} className="px-0">
                <div className="status-filter-nav sticky-top">
                  <p className="nav-category-head font-size-12 px-3 m-0 sticky-top">
                    Status
                  </p>

                  <ConsoleStatus
                    fetchRecord={this.setStatusValue}
                    statuses={statuses}
                    changeDescriptionSearchString={
                      this.changeDescriptionSearchString
                    }
                  />

                  <p className="nav-category-head font-size-12 px-3 m-0 sticky-top">
                    Group
                  </p>
                  <FarmList
                    fetchRecord={this.setFarmValue}
                    statuses={statuses.farms}
                  />
                </div>
              </Col>
              <Col className="status-alignment">
                <div>
                  <Row style={{ margin: "3px -8px" }}>
                    {isLoading ? (
                      <Spinner color="primary" />
                    ) : (
                      <ConsoleList
                        isSelectAll={isSelectAll}
                        status={status}
                        descriptionSearchString={descriptionSearchString}
                        updateSeletectConsole={this.updateSeletectConsole}
                        customer_farm_name={customer_farm_name}
                        data={recordList}
                        setStatuses={this.setStatuses}
                        refetchConsole={this.refetchConsole}
                      />
                    )}
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <ToastsContainer
          position={ToastsContainerPosition.TOP_CENTER}
          store={ToastsStore}
        />
      </main>
    );
  }
}

export default Status;
